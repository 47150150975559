.animated-graphs{
  @include u-maxw('mobile');
  @include u-margin-bottom(3);
}
.graph-container {
  @include u-margin-bottom(1);
  .graph-fill {
    @include u-bg('white');
  }
  &:nth-child(1) {
    .graph-fill {
      width: 33%;
      @include u-bg('mint-30v');
    }
  }
  &:nth-child(2) {
    .graph-fill {
      width: 85%;
      @include u-bg('accent-warm-light');
    }
  }
  &:nth-child(3) {
    .graph-fill {
      width: 50%;
      @include u-bg('cyan-30v');
    }
  }
  .graph-fill.empty {
    width: 0%;
  }
}
.graph-bg {
  width: 100%;
  height: 1em;
  @include u-outline('1px');
  @include u-outline-color('base-light');
}
.graph-fill {
  height: 100%;
  transition: width .5s ease;
}