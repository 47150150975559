@use "sass:math";

.home-vision__container {
  @include grid-container;
  @include u-margin-top(5);
}

.home-vision__dot {
  @include u-radius('pill');
  @include u-bg('white');
  @include u-width(4);
  @include u-height(4);
  @include u-margin('2px');
  // @include u-padding('1px');
}

.home-vision__row {
  @include grid-row;
  justify-content: center;
  // COLOR THE DOTS
  &:nth-child(1) .home-vision__dot:nth-child(4),
  &:nth-child(2) .home-vision__dot:nth-child(6),
  &:nth-child(3) .home-vision__dot:nth-child(1),
  &:nth-child(4) .home-vision__dot:nth-child(2),
  &:nth-child(5) .home-vision__dot:nth-child(6) {
    visibility: hidden;
  }

  &:nth-child(2) .home-vision__dot:nth-child(1),
  &:nth-child(3) .home-vision__dot:nth-child(4),
  &:nth-child(3) .home-vision__dot:nth-child(5),
  &:nth-child(4) .home-vision__dot:nth-child(6),
  &:nth-child(6) .home-vision__dot:nth-child(2) {
    @include u-bg('base-light');
  }
  &:nth-child(2) .home-vision__dot:nth-child(3),
  &:nth-child(5) .home-vision__dot:nth-child(1),
  &:nth-child(5) .home-vision__dot:nth-child(4) {
    @include u-bg('base-darker');
  }

  &:nth-child(2) .home-vision__dot:nth-child(5) {
    @include u-bg('secondary');
  }

  &:nth-child(3) .home-vision__dot:nth-child(2) {
    @include u-bg('accent-warm-light');
  }
  &:nth-child(5) .home-vision__dot:nth-child(5) {
    @include u-bg('primary');
  }
}

// ANIMATE THE DOTS
@for $i from 1 through 36 {
  $row: math.ceil(math.div($i, 6));
  $col: ($i % 6) + 1;
  $doAnimation: math.random() > 0.3;
  @if $doAnimation {
    $delay: math.random() * 6s;
    $duration: math.random() * 15s + 6s;
    $anim-name: fade-out-in;
    .home-vision__row:nth-child(#{$row}) .home-vision__dot:nth-child(#{$col}) {
      animation: $duration ease-in $delay infinite $anim-name;
      @media (prefers-reduced-motion) {
        animation: none;
      }
    }
  }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
//  @-webkit-keyframes fade-out-in {
//   0% {
//     opacity: 1;
//   }
//   20% {
//     opacity: 0;
//   }
//   40% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 1;
//   }
// }
@-webkit-keyframes fade-out-in {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out-in {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out-in--long {
  0% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out-in--long {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
