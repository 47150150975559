/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.0.0 Beta 6
----------------------------------------
COMPONENT SETTINGS
----------------------------------------
Read more about settings and
USWDS style tokens in the documentation:
https://v2.designsystem.digital.gov/style-tokens
----------------------------------------
*/

// Accordion
$theme-accordion-border-width:        0.5;
$theme-accordion-border-color:        'base-lightest';
$theme-accordion-font-family:         'body';

// Alert
$theme-alert-bar-width:               1;
$theme-alert-font-family:             'ui';
$theme-alert-icon-size:               4;
$theme-alert-measure:                 3;
$theme-alert-padding-x:               2.5;

// Banner
$theme-banner-font-family:            'ui';
//$theme-banner-max-width:              'full';

// Button
$theme-button-font-family:            'ui';
$theme-button-border-radius:          'md';
$theme-button-small-width:            6;
$theme-button-stroke-width:           2px;

// Footer
$theme-footer-font-family:            'body';

// Form and input
$theme-checkbox-border-radius:        'sm';
$theme-form-font-family:              'ui';
$theme-input-line-height:             3;
$theme-input-max-width:               'mobile-lg';
$theme-input-select-border-width:     2px;
$theme-input-select-size:             2.5;
$theme-input-state-border-width:      0.5;

// Header
$theme-header-font-family:            'ui';
$theme-header-logo-text-width:        33%;
//$theme-header-max-width:              'desktop';
//$theme-header-min-width:              'full';

// Hero
//$theme-hero-image:                  null;

// Navigation
$theme-navigation-font-family:        'ui';
$theme-navigation-width:              'desktop';

// Search
$theme-search-font-family:            'ui';
$theme-search-min-width:              27ch;

// Sidenav
$theme-sidenav-current-border-width:  0.5;
$theme-sidenav-font-family:           'ui';

/*****************
 * Process list
 *****************/
// $theme-process-list-counter-background-color: 'primary-light';
$theme-process-list-counter-border-width: 2px;

//  $theme-process-list-counter-font-family 
// Font family of the counter text.

//  $theme-process-list-counter-font-size 
// Font size of the counter text.

// $theme-process-list-counter-gap-color: 'secondary';
// Color of gap between counter and connector. This should the the same as the background color behind the component.

$theme-process-list-counter-gap-width: 0;
// Size of gap between counter and connector.

//  $theme-process-list-counter-size 
// Size of the counter circle.

//  $theme-process-list-counter-text-color 
// Color of the number text inside the counter circle.

$theme-process-list-connector-color: 'black';
// Color of the connector line between counter circles.

$theme-process-list-connector-width: 2px;
// Width of the connector line between counter circles.

//  $theme-process-list-font-family 
// Font family of the content text.

//  $theme-process-list-font-size 
// Font size of the content text.

//  $theme-process-list-heading-color 
// Color of the heading text.

//  $theme-process-list-heading-font-family 
// Font family of the heading text.

//  $theme-process-list-heading-font-size 
// Font size of the heading text.