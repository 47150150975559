// @use 'mixins';
html {
  height: 100vh;
  // background-color: $color-primary-dark;
}

/**
 * Collections!
 */
.usa-collection__body {
  @include u-padding-left(2);
  @include u-padding-right(2);
}

.usa-collection__meta-item {
  text-transform: uppercase;
  @include u-font-family('mono');
  @include u-font-size('mono', '3xs');
}

.usa-collection__heading {
  font-size: 2em;
  margin: .5em 0;
  line-height: 1.2;
  font-weight: 300;
  @include u-padding-right('205');

  @include dark-links;

  &.text-white {
    a {
      color: white;
    }
  }
}

.usa-collection__description {
  margin: 0;
  @include u-padding-right(8);
}

.usa-nav__primary-item {
  border-top: none;
}

.usa-sidenav a:not(.usa-button){
  @media (hover: none) {
    &:hover {
      background-color: transparent;
      color: inherit;
    }
  }
}