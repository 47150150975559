// ========================================
// CUSTOM PROJECT SASS
// ----------------------------------------
// Custom project SASS goes here.
//
// Contents
// 1. Generic
// 2. Site layout
// 3. Custom page styles
// ----------------------------------------

@import 'uswds_overrides';
@import 'mixins';

// TODO: move to uswds once finished
$theme-site-cols-split: "tablet";
$theme-site-cols-show: "desktop";
$theme-grid-gap-default: "md";

$site-measure: 5;

@mixin grid-cols($default, $split: null, $full: null) {
  @if $default != null {
    @include grid-col($default);
  }
  @if $split != null {
    @include at-media($theme-site-cols-split) {
      @include grid-col($split);
    }
  }
  @if $full != null {
    @include at-media($theme-site-cols-show) {
      @include grid-col($full);
    }
  }
}

@mixin usa-grid-container {
  @include grid-container;
}

.usa-grid-container {
  @include usa-grid-container;
}

@mixin usa-grid-row {
  @include grid-row;
  @include u-flex("column", "align-stretch");
  @include at-media($theme-site-cols-split) {
    @include grid-gap($theme-grid-gap-default);
    @include u-flex("row");
  }
}

.usa-grid-row {
  @include usa-grid-row;
}

@mixin usa-grid-col {
  @include grid-cols("auto", 6);
  width: 100%;
}

.usa-grid-col {
  @include usa-grid-col;
}


// ========================================
// 1. Generic
// ----------------------------------------

$site-primary-family: "blue-warm";
$site-primary-lighter: "#{$site-primary-family}-10";
$site-primary-light: "#{$site-primary-family}-30";
$site-primary: "#{$site-primary-family}-50";
$site-primary-vivid: "#{$site-primary-family}-50v";
$site-primary-dark: "#{$site-primary-family}-70";
$site-primary-darker: "#{$site-primary-family}-80";
$site-ink: "black-transparent-80";
$site-ink-alt: "white";

$site-border-width: 2px;
$site-base-transparent-light: "black-transparent-10";
$site-background-color: "gray-1";

$site-h2-size: 2.25em;

.default-container {
  @include u-bg($site-background-color);
}

html,
body {
  @include typeset("lang");
  height: auto;
  color: color($site-ink);
}

.usa-center {
  text-align: center;
}

.usa-prose site-prose {
  @include typeset("lang");
}

h2 {
  font-size: $site-h2-size;
}

h1, h2, h3 {
  scroll-margin-top: 5rem;
}
#results-count {
  scroll-margin-top: 7rem;
}

.bg-white {
  h1, h2, h3 {
    @include u-color('base-darkest');
  }
}
.usa-dark-background, .usa-section--dark {
  h2, h2, h3 {
    @include u-color('white');
  }
}

// ========================================
// 2. Site layout
// ----------------------------------------

// Header ------------------------------ //

.sticky {
  position: sticky;
  top: 0;

  &::before,
  &::after {
    content: "";
    display: table;
  }
}

.site-header {
  background-color: color("base-darkest");
  @include typeset("lang");

  @include at-media("desktop") {
    .buttons {
      .usa-button {
        position: relative;
        bottom: units(-2px);
      }
      .site-button-standards {
        box-shadow: inset 0 0 0 2px color("gold-30v");
        color: color("gold-30v");
      }
    }
  }

  .site-logo {
    margin-left: units($theme-site-margins-mobile-width);

    @include at-media("desktop") {
      display: initial;
      float: left;
      margin-bottom: 0;
      margin-left: units($theme-site-margins-width);
    }

    .usa-logo__text {
      @include at-media("desktop") {
        display: initial;
        letter-spacing: ls(-1);
      }
    }
  }

  .usa-button-list {
    display: none;
    margin-top: units(-0.5);

    @include at-media("desktop") {
      float: right;
      line-height: units(10);
      margin-top: 0;
      display: block;
      padding-right: units($theme-site-margins-width);
    }

    // scss-lint:disable SelectorDepth, NestingDepth
    li {
      display: inline;
      font-family: font-family("lang");

      &:last-child .usa-button {
        margin-right: 0;
      }
    }

    .usa-button {
      @include u-margin-y(0);
    }
  }

  .usa-button--outline-inverse {
    background-color: transparent;
    margin-right: 0;
    &:hover {
      background-color: transparent;
    }
  }

  .navbar--container {
    @include at-media("desktop") {
      align-items: center;
      display: flex;
      // padding-right: units(3);
      height: units(10);
      margin-top: -1px;
      margin-bottom: -4px;
    }

    .usa-button {
      display: none;

      @include at-media("desktop") {
        display: initial;
      }
    }

    .usa-accordion__button {
      &[aria-expanded="false"] {
        @include add-background-svg("angle-arrow-down-white");
        &:hover {
          @include add-background-svg("angle-arrow-down-primary");
        }
      }
      &[aria-expanded="true"] {
        &:hover {
          @include add-background-svg("angle-arrow-up-primary");
        }
      }
    }
  }

  .usa-menu-btn {
    font-size: fs("lang", "3xs");
    width: initial;
  }
}

.navbar__search {
  .usa-input {
    @include at-media("desktop") {
      background-color: color("blue-warm-90");
      color: color("white");
      height: 39px;
    }
  }

  .usa-button {
    @include at-media("desktop") {
      height: 39px;
    }
  }
}

.site-banner {
  background-color: color("primary-lightest");
  color: color("primary-dark");
  align-items: center;

  .usa-banner__inner {
    max-width: fit-content;
  }

  .usa-banner__button {
    color: color("base-light");

    &:hover {
      color: color("white");
    }
  }

  .usa-banner__header-text {
    color: color("primary-dark");
  }

  .usa-banner__header-action {
    color: color("gray-30");
  }
}

.site-header-navbar {
  border-bottom: color("base-light");
  &--bordered {
    @include u-border-bottom('05');
    @include u-border-color('primary');
    align-items: baseline;
    @include at-media('desktop'){
      @include u-border-color('secondary');
    }
  }

  @include at-media("desktop") {
    height: units(10);
    width: 100%;
    display: block;
  }

  // Firefox fix so that navbar buttons to align right
  @-moz-document url-prefix() {
    @include at-media("desktop") {
      padding-top: units(1px);
    }
  }

  .get-involved {
    display: none;

    @include at-media("desktop") {
      display: inline-block;
      height: units(10);
      @include u-margin(0);
      @include u-margin-left('auto');
      @include u-padding-top(4);
    }
  }

  .download-pdf {
    display: none;

    @include at-media("desktop") {
      display: inline-block;
      position: absolute;
      right: 0;
      margin-top: -1px;
    }
  }
}

@mixin outline-inverse-button {
  // TODO: Fix this with button mixin
  .usa-button.usa-button--outline-inverse {
    box-shadow: inset 0 0 0 units(2px) color($site-primary);
    color: color($site-primary);

    &:hover {
      box-shadow: inset 0 0 0 unit(2px) color($site-primary-dark);
      color: color($site-primary-dark);
    }
  }
}

.sidenav-mobile {
  @include outline-inverse-button;

  @include at-media("desktop") {
    display: none;
  }

  .usa-button-list {
    margin-top: units(4);
  }

  .usa-button {
    margin-top: units(1);
  }
}

.site-logo {
  .logo-image-mobile{
    max-width: 150px;
  }

  &.logo-image-desktop {
    display: none;
    width: 300px;
  }

  @include at-media("desktop") {
    &.logo-image-mobile {
      display: none;
    }
    &.logo-image-desktop {
      display: block;
    }
  }



  @include at-media("desktop") {
    margin-top: units(2px);
  }

  a {
    color: color($site-ink);
  }

  .logo-image {
      vertical-align: middle;
    }

  .usa-logo__text {
    $logo-size-mobile: 3;
    $logo-size-desktop: 3;
    @include u-font("lang", "xs");
    line-height: units(5);

    &::before {
      @include u-square($logo-size-mobile);
      content: "";
      // background-image: url("../TOP-logo.png");
      // background-repeat: no-repeat;
      // background-size: 100% auto;
      display: inline-block;
      margin-left: units(-2px);
      margin-right: units(0.5);
      position: relative;
      top: units(-1px);
      vertical-align: middle;

      @include at-media("desktop") {
        @include u-square($logo-size-desktop);
      }
    }

    @include at-media("desktop") {
      @include typeset("lang", "lg", 1);
      line-height: units(9);
    }
  }
}

.site-nav-secondary {
  display: none;


  @include at-media("desktop") {
    background-color: color("white");
    border-bottom: units($site-border-width) solid
      color($site-base-transparent-light);
    clear: both;
    display: block;
    height: units($height-nav-secondary);
    padding-left: units(2);
    z-index: z-index($z-nav-secondary);
  }

  &.sticky {
    top: 5rem;
    position: -webkit-sticky !important;
  }

  ul {
    @include unstyled-list;

    li {
      display: inline;
    }
  }

  a {
    @include typeset("lang", "xs", 1);
    color: color("gray-50");
    display: inline-block;
    font-weight: fw("normal");
    padding: units(2);
    position: relative;
    text-decoration: none;
    height: 100%;

    &:active {
      @include add-bar(0.5, 'secondary', "bottom", 0, 2, 0);
      color: color('secondary');
    }
    @media( hover: hover ){
      &:hover {
        @include add-bar(0.5, 'secondary', "bottom", 0, 2, 0);
        color: color('secondary');
      }
    }
    &.highlight {
      @include add-bar(0.5, 'secondary', "bottom", 0, 2, 0);
      color: color('base-darkest');
      font-weight: fw('bold');
      &:hover, &:active {
        color: color('base-darkest');
      }
    }
  }

  &.usa-current {
    @include add-bar(0.5, $site-primary-vivid, "bottom", 0, 2, 0);
    color: color($site-ink);
    font-weight: fw("bold");

    &:hover {
      color: color($site-ink);
    }
  }


  &.sprint-nav {
    @include u-position('sticky');
    top: 3.1em;
    text-align: center;
    background-color: color("white");
    border-bottom: units($site-border-width) solid
      color($site-base-transparent-light);
    clear: both;
    display: block;
    z-index: z-index($z-nav-secondary);

    overflow-x: scroll;

    overflow-y: hidden;
    ul {
      min-width: 740px;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    @include at-media('desktop'){
      top: 5em;
      height: 100%; //units($height-nav-secondary);
      overflow-x: hidden;
      ul {
        width: auto;
      }
      li {
        float: none;
      }
    }
  }
}


// Sidebar Nav --------- //

.site-sidenav.usa-sidenav {
  @include typeset("lang", "xs", 3);
  background-color: color("white");
  border: units($site-border-width) solid color("black-transparent-10");
  border-radius: radius("md");
  padding: 0;

  a {
    @include u-padding-x(2);
    color: color("base-darker");

    &:hover {
      background-color: transparent;
      color: color($site-primary-vivid);
    }
  }

  .usa-current {
    color: color("base-darker");
    position: static;

    &::after {
      border-radius: 0;
      bottom: units(0);
      background-color: color($site-primary-vivid);
      bottom: units(-1px);
      left: units($site-border-width) * -1;
      top: units(0);
      z-index: z(100);
    }
  }

  .usa-sidenav__item:first-child {
    .usa-current::after {
      border-top-left-radius: radius("md");
      top: units($site-border-width) * -1;
    }
  }

  .usa-sidenav__item:last-child {
    .usa-current::after {
      border-bottom-left-radius: radius("md");
      bottom: units($site-border-width) * -1;
    }
  }

  .usa-sidenav__item {
    border-top: none;
    line-height: lh("lang", 3);
    position: relative;

    & + .usa-sidenav__item {
      a {
        // TODO: use 'black-transparent-5' when value changes
        border-top: units(1px) solid rgba(0, 0, 0, 0.05);
      }
    }

    &.is-current {
      background-color: color($site-background-color);

      .is-current {
        background-color: transparent;
      }
    }
  }

  .usa-sidenav__sublist {
    font-size: font-size("lang", "3xs");
    a {
      border-top: units(1px) solid rgba(0, 0, 0, 0.05);
      padding-left: units(2);
    }

    .usa-current {
      color: color($site-primary-vivid);
    }

    .usa-sidenav__sublist {
      a {
        padding-left: units(4);
      }
    }
  }
}

.sidenav {
  display: none;
  position: sticky;
  top: 150px;
  left: 100px;

  @include at-media("desktop") {
    display: block;
    float: left;
    margin-bottom: units(6);
    margin-top: units(4);
    padding-right: 0;
    // padding-left: units(4);
    width: units($width-nav-sidebar);
  }

  .usa-navigation-submenu {
    &[aria-hidden="false"] {
      display: block;
    }
  }
}

// Main Content --------- //

main {
  scroll-margin-top: 2.5em;
}

.main-content {
  @include u-pin("right");
  @include u-pin("bottom");
  display: inline-block; // starting: not scrolled
  margin-top: units(5); // starting: not scrolled
  position: relative;
  width: 100%;

  @include at-media("desktop") {
    width: calc(100% - #{units($width-nav-sidebar)});
  }

  .lt-ie9 & {
    width: 75%;
  }
}

// Ensure the homepage accordion displays properly
// TODO: make sure line height is added in USWDS accordion component and that
// list styles don't override accordion styles
#main-content {
  // scss-lint:disable IdSelector
  .usa-accordion__button {
    line-height: 1;
  }
}

.site-h2 {
  @include typeset("lang", "xl", 2);
  color: color($site-ink);
  margin-top: units(4);

  & + p {
    margin-top: units(1.5);
  }
}

.interior-hero {
  background-color: #004280;
  color: #ffffff;
  position: relative;
  z-index: 5;
  padding-top: 5rem;

  &__bg {
    background-size: cover;
    background-repeat: no-repeat;
    padding: 0 4.5rem;
    border-radius: 10px;
  }
  &__callout {
    color: #ffffff;
  }
  &__heading {
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 0;
    margin-top: 2rem;
  }
  &__title {
    font-size: $theme-type-scale-xl;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 0;
    margin-top: 2rem;
  }
}

// styling for the alternative h2 so that the correct header tags can still be used even though they are visually distinct
.tag-header-dark {
  padding: units(1);
  background-color: color("base-darkest");
  color: color("white");
  margin-top: -19px;
  font-family: font-family("mono");
  font-size: font-size("mono", "3xs");
  width: 140px;
  text-align: center;
}

.tag-header {
  padding: units(1);
  background-color: color("primary");
  color: color("white");
  margin-top: -19px;
  font-family: font-family("mono");
  font-size: font-size("mono", "3xs");
  width: 130px;
  text-align: center;
}

.tag-subheader {
  padding: units(1);
  background-color: color("primary");
  color: color("white");
  font-family: font-family("mono");
  font-size: font-size("mono", "3xs");
  width: 130px;
  text-align: center;
}


.vertical-line-light {
  border-left: 1px solid color("white");
  position: relative;
  height: units(7);
  width: 50%;
  left: 50%;
  top: 0;
}

.vertical-line {
  border-left: 1px solid;
  position: relative;
  height: units(7);
  width: 50%;
  left: 50%;
  top: 0;
}

.circuit-banner {
  @include at-media("desktop") {
    display: block;
  }
}

/* Re-style usa banner */

.usa-accordion {
  margin: 0;
  list-style-type: none;
  opacity: .75;
  padding: 4px 0;
  width: 100%;
  }

.usa-banner__header-text {
  font-size: 11px;
  font-weight: 500;
}
.usa-banner__button-text {
  color: color("primary-dark");
}

.usa-section--dark a.usa-link:visited {
  @include u-color('primary-lightest');
  &:hover {
    @include u-color('primary-lighter');
  }
}

.border-stars {
  margin-top: units(-7);
  margin-bottom: units(7);
}

.clickable-card, .product-card {
  &:hover {
    cursor: pointer;
  }
  &:focus, &:focus-within {
    @include u-outline('05', $theme-focus-color)
  }
}


// datakit hub

.data-kit {
  @include at-media("mobile") {
    padding: 0 5rem;
    margin-bottom: -1rem;
  }

  .data-kit__card {
    // width: 90%;
    position: relative;
    .usa-card__container {
      border-width: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-blend-mode: color;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 4;
      padding: 9rem 2rem 3.5rem;
      @include at-media('desktop-lg') {
        @include u-padding-x(7)
      }
      border-radius: 10px;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: font-size("sans", "xl");
      font-weight: 700;
      line-height: line-height("lang", 4);
      color: color("white");
    }
    p {
      font-size: font-size("sans", "sm");
      color: color("white");
      line-height: line-height("lang", 3);
      margin-bottom: 2rem;
    }
    // .data-kit__arrow {
    //   float: right;
    //   z-index: 10;
    //   transition: all 500ms ease;
    // }
    p {
      position: relative;
      // z-index: 5;
    }
    a {
      text-decoration: none;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .data-kit__active {
    $datakitBoxShadow: 0 0 0 3px color('base-lightest');
    .usa-card__container {
      transition: transform 400ms ease;
      &:focus-within {
        box-shadow: $datakitBoxShadow;
        a:focus {
          outline: none;
        }
      }
      // cursor: pointer;
      // pointer-events: none;
      // h2 {
      //   pointer-events: auto;
      // }
      &:hover, &:active {
        box-shadow: $datakitBoxShadow;
        // box-shadow: 2px 1px 5px 0px rgba(0, 0, 0, 0.25);
        // transform: translate(-5px, -10px);
        // .data-kit__arrow {
        //   animation: bounce 2s infinite;
        // }
      }
    }
  }

  .data-kit__bg {
    height: 115%;
    background-color: #13192F;
    position: absolute;
    z-index: 1;
    border-radius: 10px;
    top: 1.5rem;
    left: 1.5rem;
    width: 90%;
  }
  .data-kit__upcoming {
    text-transform: uppercase;
    font-size: font-size("sans", "2xs");
    color: #13192F;
    font-weight: 600;
    letter-spacing: 0.8px;
    padding: 0.5rem 0.8rem;
    background-color: #F7C636;
    border-radius: 4px;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
  }
  .data-kit__stats {
    color: color("primary-dark");
    font-weight: 400;
    letter-spacing: 0.8px;
    padding: 0.5rem 0.8rem;
    background-color: color("white");
    border-radius: 4px;
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    .data-kit__icon-items {
      display: inline-block;
      margin-right: 0.5rem;
      vertical-align: middle;
    }
    p {
      display: inline-block;
      margin: 0;
      font-size: 1.4rem;
      line-height: 1.4rem;
      vertical-align: middle;
    }
  }

}


.data-kit__button {
  text-decoration: none;
}

.data-kit__suggest {
  transition: all 400ms ease;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  padding: 12em 0;
  @include at-media("mobile") {
    width: 100%;
  }
  .data-kit__icon-suggestion {
    opacity: 0.8;
  }
  p {
    color: color("white");
    font-weight: 600;
    font-size: font-size("sans", "lg");
    opacity: 0.8;
  }

  &:hover {
    .data-kit__icon-suggestion,
    p {
      opacity: 1;
    }
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(5px);
  }
  60% {
    transform: translateX(2px);
  }
}

.data-source__button {
  background-color: transparent;
  border: none;
  &:hover {
    .data-source p {
      border-bottom-style: solid;
    }
  }
}

.data-card-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  @include u-margin-x(-1);
  list-style-type: none;
}

.dch__data-topic {
  @include u-border(solid, 'accent-warm', 2px);
  @include u-bg('primary-darker');
  cursor: pointer;
  @include u-margin-x(0.5);
  @include u-radius(1);
  @include u-color('white');
  &.selected {
    @include u-bg('white');
    @include u-color('ink');
    @include u-border-color('primary-darker');
    cursor: initial;
  }
}

.challenge-widget {
  @include u-bg('white');
  @include u-radius('md');
  @include u-text('ink');
}

.challenge-widget__header{
  @include u-line-height('sans', 5);
  @include u-font('sans', 'lg');
  @include u-border-bottom('1px');
  @include u-margin-top(0);
  @include u-padding-bottom(3);
}

.challenge-widget__subheader {
  text-transform: uppercase;
  @include u-text('normal');
  @include u-color('base');
  @include u-margin-bottom(1);
  & + p {
    @include u-margin-top(0);
  }
}
.challenge-widget__column {
  @include at-media('desktop') {
    @include u-maxh('tablet');
  }
}

.challenge-widget__content-section {
  @include u-line-height('sans', 6);
  &:first-child {
    @include u-margin-bottom(4);
  }
}

.challenge-widget__image-container {
  @include u-radius('md');
  @include u-bg('primary-darker');
  @include grid-col(12);
  @include u-order('first');

  @include at-media-max('tablet-lg') {
    @include u-height('card');
  }

  @include at-media('tablet-lg') {
    @include grid-col(6);
    @include u-order('last');
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.challenge-widget__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  @include u-radius('md');
  @include at-media('tablet-lg') {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.site-radio {
  @include u-bg('transparent');
  [class$=__label] {
    @include u-radius('pill');
    @include u-border('2px', 'solid', 'white');
    @include u-bg('base-darker');
    @include u-color('white');
    @include u-width(4);
    @include u-height(4);
    padding: .25rem .5rem;
    text-indent: 0;
    &::before {
      display: none;
    }
  }
  input:checked+[class$=__label] {
    @include u-border(0);
    @include u-bg('white');
    @include u-color('ink');
  }
}

.dch-radio {
  background-color: initial !important;
  .dch-radio__label {
    @include u-border-color('white');
    @include u-bg('primary-dark');
    @include u-color('white');
    @include u-padding-left(2);
    text-indent: 0;
    &::before {
      display: none;
    }
  }
  input:checked+[class$=__label] {
    @include u-bg('white');
    @include u-text('ink');
    @include u-border-color('primary-darker');
  }
}

.dch__checkbox-group {
  border: none;
}

.dch-checkbox {
  background-color: transparent;
  label {
    color: white;
    &::before {
      background-color: transparent;
      box-shadow: 0 0 0 2px color('white');

    }
  }
  input:checked+label::before {
    background-image: url(../img/correct-primary-dark.svg) !important;
    background-color: white !important;
    box-shadow: 0 0 0 2px color('primary-dark') !important;
  }
}

.dch-reset-button {
  &[disabled] {
    @include u-bg('base-light');
    cursor: default;
    &:hover {
      @include u-bg('base-light');
    }
  }
}

.dch-card__tag {
  background-color: transparent !important;
  border: 1px solid;
  @include u-border-color('base-lightest');
  @include u-color('base-lightest', !important);
  margin-right: .1rem;
  &.usa-tag--new {
    @include u-text('bold');
    @include u-bg('base-lightest', !important);
    @include u-color('primary-darker', !important);
  }
}

.dch-card__steward-link {
  overflow-wrap: break-word;
}

.dkh-details {

  color: color("white");
  h3 {
    font-weight: 700;
    font-size: font-size("sans", "lg");
    margin-bottom: 1.5rem;
  }
  &-p {
    line-height: 30px;
    border-bottom: 1.5px dotted;
    @include u-padding-bottom(2);
    font-weight: 400;
    font-size: font-size("sans", "sm");
    @include white-links;
  }
  &-p:last-child {
    border-bottom: none;
  }
}



// datasets

@keyframes show {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.topics {
  background-color: color("primary-vivid");
  position: relative;
  top: -10rem;
  margin-bottom: -13.2rem;
  &__container {
    padding-top: 10rem;
  }
  &__row {
    margin: 3rem auto;
    padding-bottom: 3rem;
    border-bottom: 5px solid #F7C636;
  }
  &__card {
    background-color: color("primary-dark");
    padding: 2.5rem 2rem;
    border-radius: 4px;
    margin-right: 1.5rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid color("primary-dark");
    transition: border 400ms ease;
    p {
      font-size: font-size("sans", "2xs");
      font-weight: 400;
      color: color("white");
    }
    &:hover {
      cursor: pointer;
      border: 1.5px solid #F7C636;
    }
    &--active {
      border: 1.5px solid #F7C636;
    }
  }
}

.challenge {
    background-color: color("primary-vivid");
    margin-bottom: -0.2rem;
    &__container {
      padding: 3.5rem 0;
      display: none;
      &--show {
        display: block;
        opacity: 0;
        animation-name: show;
        animation-fill-mode: forwards;
        animation-duration: 1s;
      }
    }
    &__image {
      display: inline-block;
      max-height: 165px;
      border-radius: 4px;
    }
    &__text {
      display: inline-block;
      color: color("white");
      font-family: font-family("sans");
      vertical-align: top;
      margin-left: 3rem;
      &-caption {
        font-size: font-size("sans", "2xs");
        text-transform: uppercase;
        letter-spacing: 1px;
        color: #F7C636;
        font-weight: 600;
        margin: 0;
      }
      &-title {
        font-size: font-size("sans", "3xs");
        font-weight: 600;
        margin: 1rem 0;
      }
      &-description {
        font-size: font-size("sans", "2xs");
        font-weight: 400;
        margin: 0;
      }
      &-link {
        font-size: font-size("sans", "2xs");
        font-weight: 400;
        transition: all 400ms ease;
        a {
          color: color("white");
          -webkit-text-decoration: #F7C636 dotted underline;
          text-decoration: #F7C636 dotted underline;
          -webkit-text-underline-position: under;
          text-underline-position: under;

          &:hover {
            text-decoration-style: solid;
          }
        }
      }
    }
    &__text-description-mobile {
      display: none;
      font-size: font-size("sans", "2xs");
      font-weight: 400;
      color: color("white");
      margin: 0;
    }
    &__text-link-mobile {
      display: none;
      font-size: font-size("sans", "2xs");
      font-weight: 400;
      transition: all 400ms ease;
      a {
        color: color("white");
        -webkit-text-decoration: #F7C636 dotted underline;
        text-decoration: #F7C636 dotted underline;
        -webkit-text-underline-position: under;
        text-underline-position: under;
      }
    }
}

.external-link-white {
  .external-link {
    display: none;
  }
}

.datasets__card {
    background-color: color("primary-vivid");
    border: color("base-darkest");
    border-radius: 10px;
    color: color("white");
    font-size: font-size("sans", "2xs");
    transition: border 400ms ease;
    border: 2px solid color('primary-vivid');
    h2 {
      padding-right: 2rem;
    }

    .external-link {
      // display: none;
      display: block;
      float: right;
      margin: units(2);
      opacity: 0;
      transition: all 200ms ease;
    }

    .listed-link {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .datasets__featured-banner {
    border-radius: 10px 10px 0 0;
    @include u-padding-x(3);
    @include u-color('accent-warm');
    @include u-text('italic');
    @include u-margin-top(2);
  }


// Sprint process page ----------------- //
.page-sprint-process {
  h2 {
    font-size: 14px;

    @include at-media('tablet') {
      font-size: 20px;
      line-height: 20px;
    }
    @include at-media('desktop') {
      font-size: 32px;
      line-height: 32px;
    }
  }

  h3 {
    font-size: 12px;
    @include at-media('tablet') {
        font-size: 14px;
        line-height: 14px;
      }
    @include at-media('desktop') {
        font-size: 24px;
        line-height: 24px;
      }
  }

  p {
    font-size: 12px;
    line-height: 17px;
    @include at-media('tablet') {
        font-size: 14px;
        line-height: 17px;
      }
    @include at-media('desktop') {
        font-size: 24px;
        line-height: 30px;
      }
  }

  .interior-hero {
    padding-bottom: 60px;
    // background: url('../img/sprint-process-page/tablet-hero-background.png') repeat 0 0;
      background-size: contain;
    @include at-media("desktop") {
      // background: url('../img/sprint-process-page/desktop-hero-background.png') no-repeat 0 0;
      background-size: cover;
      padding: 117px 0 124px;
    }
  }

  .usa-hero__bg {
    width: 200px;
    min-height: 114px;
    background-color: #121923;
    @include at-media('tablet') {
      width: 343px;
      // min-height: ;
    }

    @include at-media('desktop') {
        width: 571px;
      }

    h1.usa-hero__title {
      font-size: 14px;
      margin: 0;
      padding: 0;
      padding-top: 25px;

      @include at-media('tablet') {
        font-size: 20px;
        line-height: 20px;
      }

      @include at-media('desktop') {
        font-size: 32px;
        line-height: 32px;
        padding-top: 30px;
      }
    }
    div.usa-hero__subtitle {
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 0;
      padding-bottom: 16px;
      @include at-media('tablet') {
          font-size: 14px;
          line-height: 17px;
        }
        @include at-media('desktop') {
          font-size: 32px;
          line-height: 32px;
          padding-bottom: 30px;
        }
         p {
          margin: 8px 0 0px;
          @include at-media('desktop') {
              margin-top: 20px;
            }
         }
    }
  }

  .how-it-works-bg, .roles-intro-bg {
    background: url('../img/sprint-process-page/desktop-roles-background.png') no-repeat 0 0;
    background-size: cover;
  }

  div.header-text-hot-dog, div.how-it-works-details {
    padding: 52.64px 30.08px;

    @include at-media('tablet') {
      padding: 52.64px 67.68px;
    }
    @include at-media('desktop') {
      padding: 112px 127.5px;
    }
  }
  div.grid-container div.text-wrapper {
    margin: 15.04px 0;

    @include at-media('tablet') {
        margin: 30px 0 8px;
      }
    @include at-media('desktop') {
        margin: 30px 0px 0px;
      }
        a {
        font-size: 12px;
        line-height: 17px;
        @include at-media('tablet') {
            font-size: 14px;
          }
        @include at-media('desktop') {
            font-size: 24px;
          }
        }
  }
  .how-it-works-details {
    ol {
      list-style-position: inside;
      font-size: 12px;
      @include at-media('tablet') {
          font-size: 14px;
          line-height: 17px;
        }
      @include at-media('desktop') {
          font-size: 24px;
          line-height: 24px;
        }
      .usa-accordion__button {
        font-size: 12px;
        line-height: 12px;
        @include at-media('tablet') {
            font-size: 14px;
            line-height: 14px;
          }
        @include at-media('desktop') {
            font-size: 24px;
            line-height: 24px;
          }
      }
    }
  }
  .site-button.usa-button {
    box-shadow: inset 0 0 0 1px black;
    text-decoration: underline;
    font-size: 12px;
    line-height: 17px;
    @include at-media('tablet') {
        font-size: 14px;
        line-height: 17px;
      }
      @include at-media('desktop') {
        font-size: 24px;
        line-height: 30px;
      }
      &::hover {
        text-decoration: underline;
      }
      @include at-media('desktop') {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
          }
        &:visited {
            color: black;
          }
      }
  }
  .full-width-screen-spilt {
    display: grid;
    // max width of grid-container is 64
    // padding in mobile is 1rem
    grid-template-columns: minmax(30.08px, 1fr) minmax(0, auto) minmax(30.08px, 1fr);
    grid-template-rows: auto auto;
    grid-template-areas:
      ". roles ."
      "semi semi semi";

    .roles-content-block {
      grid-column: 1/2;
      grid-area: roles;
    }

    .roles-downloads {
      a {
        text-decoration: underline;
        color: black;
        &:visited {
          color: black;
        }
        &:hover {
          color: black;
        }
      }
    }
    .semi-cta-block {
      grid-column: 2/-1;
      justify-self: end;
      grid-area: semi;
    }
    @include at-media('tablet') {
      grid-template-columns: 67.68px auto 67.68px;
    }
    @include at-media('desktop') {
      grid-template-columns: 127.5px auto 127.5px;
    }
  }
}

// About page ----------------- //
.page-about {
  h2 {
      font-size: 14px;

      @include at-media('tablet') {
        font-size: 20px;
        line-height: 20px;
      }

      @include at-media('desktop') {
        font-size: 32px;
        line-height: 32px;
      }
    }

  h3 {
    font-size: 12px;

    @include at-media('tablet') {
      font-size: 14px;
      line-height: 14px;
    }

    @include at-media('desktop') {
      font-size: 24px;
      line-height: 24px;
    }
  }

  p,
  .coil-intro-container a.text-white,
  .header-text-hot-dog a.text-underline {
    font-size: 12px;
    line-height: 17px;

    @include at-media('tablet') {
      font-size: 14px;
      line-height: 17px;
    }

    @include at-media('desktop') {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .interior-hero {
    padding-bottom: 60px;
    // background: url('../img/about-page/desktop-hero-background.png') no-repeat center;
    background-size: cover;

    @include at-media("desktop") {
      // background: url('../img/about-page/desktop-hero-background.png') no-repeat 0 0;
      background-size: cover;
      padding: 117px 0 124px;
    }
  }

  .usa-hero__bg {
    width: 200px;
    min-height: 114px;
    background-color: #121923;

    @include at-media('tablet') {
      width: 343px;
      // min-height: ;
    }

    @include at-media('desktop') {
      width: 571px;
    }

    h1.usa-hero__title {
      font-size: 14px;
      margin: 0;
      padding: 0;
      padding-top: 25px;

      @include at-media('tablet') {
        font-size: 20px;
        line-height: 20px;
      }

      @include at-media('desktop') {
        font-size: 32px;
        line-height: 32px;
        padding-top: 30px;
      }
    }

    div.usa-hero__subtitle {
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 0;
      padding-bottom: 16px;

      @include at-media('tablet') {
        font-size: 14px;
        line-height: 17px;
      }

      @include at-media('desktop') {
        font-size: 32px;
        line-height: 32px;
        padding-bottom: 30px;
      }

      p {
        margin: 8px 0 0px;

        @include at-media('desktop') {
          margin-top: 20px;
        }
      }
    }
  }

  .about-bg,
  .history-bg {
    background: url('../img/sprint-process-page/desktop-roles-background.png') repeat 0 0;
    background-size: contain;
  }

  div.header-text-hot-dog,
  // .initiatives .initiatives-container,
  #coil-intro .coil-intro-container
    {
    padding: 52.64px 30.08px;

    @include at-media('tablet') {
      padding: 52.64px 67.68px;
    }

    @include at-media('desktop') {
      padding: 112px 127.5px;
    }
  }

  .header-text-hot-dog {
    h2.about,
    h2.our-history {
      @include at-media('desktop') {
          @include u-padding-bottom(5);
        }
    }
    a {
    width: 200px;
    color: rgba(0, 0, 0, .8);

    @include at-media('tablet') {
      width: 336px;
    }
    @include at-media('desktop') {
      width: 575px;
    }
  }
  }

  #coil-intro {
    background: url('../img/about-page/desktop-coil-team-background.png') no-repeat 0 0;
    background-size: cover;

    .coil-intro-container  {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      @include at-media('tablet') {
        flex-direction: row;
        align-items: center;
      }
    .content-container {
      @include at-media('tablet') {
      width: 262px;
      margin-right: 18px;
    }
    @include at-media('desktop') {
      width: 476px;
      margin-right: 28px;
      }
    }
    }
  }

  .full-width-screen-spilt {
    display: grid;
    // max width of grid-container is 64
    // padding in mobile is 1rem
    grid-template-columns: minmax(30.08px, 1fr) minmax(0, auto) minmax(30.08px, 1fr);
    grid-template-rows: auto auto;
    &.vision {
      grid-template-areas:
        ". contained ."
        "semi semi semi";
    }
    &.initiatives{
      grid-template-areas:
        ". contained ."
        "semi semi semi";
      }
    .contained-container {
      // grid-column: 1 / 2;
      grid-area: contained;
      padding: 60px 25px 22px;
      h2 {
        margin: 0;
      }
      @include at-media('tablet') {
        padding: 60px 0px 22px;
      }
    }
    .semi-cta-block {
      // grid-column: 2 / -1;
      justify-self: end;
      grid-area: semi;
    }
    .radius-right-pill,
    .radius-left-pill {
      width: calc(100% - 30.08px);
      @include at-media('desktop') {
        width: calc(100% - 127.5px);
      }

    }
    @include at-media('tablet') {
      grid-template-columns: 67.68px auto 67.68px;
    }
    @include at-media('desktop') {
      grid-template-columns: 127.5px auto 127.5px;
    }

      .radius-left-pill {
        @include at-media('tablet') {
          width: calc(100% - 195px);
        }
      }
      .radius-right-pill.semi-cta {
      border: none;
    }
  }

  .coil-intro-container .img-container {
    width: 260px;
    height: 178px;
    @include at-media("desktop") {
        width: 318px;
        height: 235px;
      }

    img {
      width: 163px;
      @include at-media("tablet") {
        width: 140px;
      }
      @include at-media("desktop") {
        width: 222px;
      }
    }
  }
 .initiatives {
    .contained-container {
      padding-bottom: 0px;
    }
    div.initiatives-container .usa-media-block__background-image-portrait {

      @include at-media ('tablet') {
      padding-right: 30px !important;
      padding-left: 0px !important;
      }
      &:nth-child(1) .card-header h3 a::after {
        content: "↧";
      }
   &:nth-child(3) {
        padding-right: 0px !important;
      }
    }
  .small-header-card {
    max-height: 258px;
    width: 258px;
    padding: 0;
    box-shadow: -2px 2px 2px 1px #121923;

    @include at-media("tablet") {
        width: 140px;
        height: 140px;
      }
    @include at-media("desktop") {
        width: 240px;
        height: 240px;
      }

    .card-header {
      // background-color: transparent;
      background-color: white;
      width: 100%;
      padding-right: 0;
      padding-bottom: 0;
    }
    h3 {
      a {
      display: inline-block;
      width: min-content;
      color: black;
      font-size: 14px;

      @include at-media("tablet") {
        font-size: 12px;
      }
      @include at-media("desktop") {
        font-size: 16px;
      }
      }
    }
  }
 }
}

// FAQ page ----------------- //
.page-faqs {
  background: #E6E6E6;
  .banner-background {
    background: url("../img/photos/faqs/top-faq-banner.png") no-repeat 0 0;
    background-size: cover;
  }
 #m-a13 ul li {
  max-width: none;
 }
}


// TOOLKITS ----------------- //

.content--title {
  text-decoration-color: color(red-warm-50v);
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
}

.to-do--title {
  text-decoration-color: color(red-warm-50v);
  text-decoration-line: underline;
  text-decoration-thickness: 5px;
}

.toolkit-hero-image {
  @include at-media("desktop") {
    margin-left: -50px;
  }
  display: inline;
}

.toolkit-image-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  height: 100%;


  .toolkit-blue-rectangle {
    @include grid-col;
    @include u-bg('white');
    @include u-minh(8);
    display: flex;
    // justify-content: center;
    align-items: center;
    border-left: 2px color('base-darkest') solid;
    border-right: 2px color('base-darkest') solid;

    &:nth-child(1) {
      @include u-margin-left(0);

    }
    &:nth-child(2) {

    }
    &:nth-child(3) {
      @include u-margin-right(0);
    }

    &.hide {
      // border: none;
      @include u-border-color('primary');
      @include u-bg('primary');
      @include u-maxh(4);
      margin: 0 2px;
    }
  }
}

.toolkit-colored-div {
  width: calc(100vw + 1px);
  margin-left: calc(-55vw + 55%);
}

.toolkit-resources {
  box-shadow: 0 0 10px rgba(0,0,0,0.6);
  -moz-box-shadow: 0 0 10px rgba(0,0,0,0.6);
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,0.6);
  -o-box-shadow: 0 0 10px rgba(0,0,0,0.6);

  .toolkit-resource-link {
    color: #000000;
    &::before {
      @include u-square(2);
      background: url("../img/icons/arrow-up-right.png") no-repeat 0 0;
      background-size: 100%;
      content: "";
      display: inline-block;
      margin-top: 2px;
      margin-right: units(1.5);
      text-decoration: none;
    }
    &#{$external-href}::after {
      display: none;
    }
    &:hover {
      cursor: pointer;
    }
  }

}

.toolkit-highlight {
  // color: color(base-light);
  font-style: italic;
  border-left: 2px solid color(base-light);
  margin-left: units(6);
  padding-left: units(2);
  margin-top: 0;
}

.toolkit-checkmark {
  list-style-image: url('../img/icons/check.png');

  .nested-bullet {
    margin-left: units(5);
    list-style-image: circle !important;
  }
}

.toolkit-table {
  width: 100%;
  -webkit-box-shadow: 0 8px 6px -6px rgba(0,0,0,0.4);
	   -moz-box-shadow: 0 8px 6px -6px rgba(0,0,0,0.4);
	        box-shadow: 0 8px 6px -6px rgba(0,0,0,0.4);

  th {
    border: none;
    font-weight: bold;
    padding-left: 0;
  }

  td.col-1 {
    border: 1px solid color(base-lighter);
    text-align: left;
    padding: 6px;
  }

  td.col-2 {
    border: 1px solid color(base-lighter);
    text-align: center;
  }

  td.col-3 {
    border: 1px solid #ffffff;
    text-align: left;
    padding: 6px;
  }
}

.toolkit-header, .process-section, .process-section section {
  scroll-margin-top: 10rem;
  scroll-snap-margin-top: 10rem;  // for safari mobile
}

.toolkit-header-refresh h2 {
  width: fit-content;
}

.page-topx-toolkit {
  #main-content .site-banner {
    background: #ffbe2e;
    p, a {
      color: black;
    }
  }
  section.cta-three.usa-section--dark p {
    color: color('blue-warm-60v');
  }
}

.topx-hero {
  background: color('blue-warm-60v');
  background: url('../img/toolkits/hero-image.png');
  @media screen and (min-width: 640px) {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;
    min-height: 720px;
  }
  @media screen and (min-width: 1024px) {
    min-height: 720px;
  }

  h1 {
    font-size: 3rem;
    color: white;

    &.visually-hidden {
        border: 0;
        padding: 0;
        margin: 0;
        position: absolute !important;
        height: 1px;
        width: 1px;
        overflow: hidden;
        clip: rect(1px 1px 1px 1px);
        /* IE6, IE7 - a 0 height clip, off to the bottom right of the visible 1px box */
        clip: rect(1px, 1px, 1px, 1px);
        /*maybe deprecated but we need to support legacy browsers */
        clip-path: inset(50%);
        /*modern browsers, clip-path works inwards from each corner*/
        white-space: nowrap;
        /* added line to stop words getting smushed together (as they go onto seperate lines and some screen readers do not understand line feeds as a space */
      }
  }


  .content-container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    width: 100%;

    p {
      max-width: 600px;
      padding: 10px;
    }
    .second-tagline{
      max-width: 730px;
    }
  }

  .scrolling-words-container {
      display: flex;
      font-size: 3.8rem;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      width: 100%;
      position: relative;
      // z-index: -1;
      font-weight: 700;
      padding: 0;
      color: white;

      div.scrolling-words-box {
          height: 5.5rem;
          overflow: hidden;

          ul {
            margin: 0;
            padding: 0;
            animation: scrollUp 10s infinite;


            li {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              height: 5rem;
              list-style: none;
              width: fit-content;
              width: fit-content;
            }
          }
        }
  }
  $item-count: 6;

  @keyframes scrollUp {
    @for $i from 1 through ($item-count - 1) {

      #{($i * 11) - 6%},
      #{$i * 11%} {
        transform: translateY(calc(-100% / $item-count) * $i);
      }
    }
  }
}

.video-section {
  display: flex;
  flex-direction: column;
  .page-topx-toolkit & {
    background-color: #FAF3E9;
  }

  h2 {
    margin: 0;
  }

  .iframe-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    .page-topx-toolkit & iframe {
      border: 0;
      @media screen and (min-width: 640px){
        border-bottom: color('blue-warm-60v') 15px solid;
        border-left: color('blue-warm-60v') 20px solid;
      }
    }

    div {
      align-self: center;
      max-width: 640px;
      min-width: 300px;
      padding: 35px;
      .defined-width {
        @include at-media('tablet') {
        width: 44ch;
        }
      }
      @media screen and (min-width: 1024px) {
          background-color: white;
        }
    }
  }
}

@mixin space-between-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timeline-graphic {

}

.phase--title a, .full-sprint {
  @include space-between-text;
}

.phase--title a, .full-sprint {
  @include u-padding(2);
}

.full-sprint {
  >* {
    @include u-margin-y(0);
  }
}

.phase--title a {
  width: 100%;
  color: white;
  text-decoration: none;
  >* {
     @include u-margin-y(0);
  }
}


.steps {
  // outline: red 5px dashed;
  padding: 0;
  display: flex;
  flex-flow: column;
  li {
    list-style-type: none;
    // border: 3px green solid;
    margin: 15px 0 0;
    @include u-padding-y(2);
    a {
      @include space-between-text;
      color: white;
      text-decoration: none;
      @include u-padding-x(1);
      >* {
          @include u-margin-y(0);
        }

      h5 {
        @include u-text('normal')
      }
    }
  }
}

.phase--section__phase1 .steps {
  li:first-child {
    width: 90%;
  }
  li:nth-child(2) {
    width: 80%;
    align-self: end;
    margin-right: 1.6rem;
  }
  li:nth-child(3) {
    width: 60%;
    align-self: end;
  }
}
.phase--section__phase2 .steps {
  li:first-child {
    width: 35%;
  }
  li:nth-child(2) {
    width: 60%;
    margin-left: 1.6rem;
  }
  li:nth-child(3) {
    width: 70%;
    align-self: center;
  }
  li:nth-child(4) {
    width: 70%;
    align-self: end;
    margin-right: 1.6rem;
  }
  li:nth-child(5) {
    width: 70%;
    align-self: end;
  }
}
.phase--section__phase3 .steps {
  li:first-child {
    width: 90%;
  }
  li:nth-child(2) {
    width: 80%;
    align-self: end;
    // margin-left: 1.6rem;
  }
}

div[id*=toolkit-step-] {
  scroll-snap-margin-top: 18rem;
  scroll-margin-top: 18rem;
}


.products {
   .card-container {
    background-image: none;
    border: 0;
    border-bottom: color('red-warm-50v') solid 20px;
    border-left: color('red-warm-50v') solid 13px;
  }
}

div.toolkit-todo {
  summary {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

}

div.toolkit-todo__resources {
  a {
    border-radius: 15px;
  }
}

// Styleguide Content -------- //

.styleguide-content {
  @include add-responsive-site-margins;
  margin-bottom: units(6);
  max-width: units($theme-site-max-width);
  overflow: hidden;
  position: relative;

  header {
    & + p {
      margin-top: units(1.5);
    }
  }

  > h2 {
    @extend .site-h2;
  }

  > h3 {
    @include typeset("lang", "md", 3);

    & + p {
      margin-top: units(1);
    }
  }

  > h4 {
    @include typeset("lang", "2xs", 3);
    @include u-text("uppercase", $site-ink, "normal", "ls-2");
    margin-top: units(3);

    & + p,
    & + ol,
    & + ul {
      margin-top: units(1);
    }
  }

  > h5 {
    @include typeset("lang", "2xs", 3);
    @include u-text($site-ink, "bold");
    margin-top: units(2);

    & + p,
    & + ol,
    & + ul {
      border-top: units(1px) solid color("base-lighter");
      padding-top: units(1);
      margin-top: units(0.5);
    }
  }
}

.product-roadmap-list {
  li {
    max-width: 100%;
  }
}

// Label links

%label-maturity {
  @include typeset("lang", "2xs", 4);
  color: color("ink");
  font-weight: font-weight("bold");
  margin-left: units(1.5);
  position: absolute;
  text-decoration: none;

  &#{$external-href}::after {
    display: none;
  }
}

.label-alpha,
.label-in-progress {
  @extend %label-maturity;
  background-color: color("gold-20v");
}

.label-beta,
.label-next {
  @extend %label-maturity;
  background-color: color("primary-vivid");
  color: color("white");
}

.label-upcoming {
  @extend %label-maturity;
  @include u-text("ls-1", "normal");
  @include u-border("base-light", 1px);
  background-color: color("transparent");
}

.label-recommended,
.label-done {
  @extend %label-maturity;
  background-color: color("green-cool-5");
}

.heading-margin-alt {
  margin-top: units(7);
}

.site-subheading {
  @include typeset-h6;
  @include u-font("lang", "3xs");
  color: color("black-transparent-60");
  letter-spacing: letter-spacing(1);
  margin-bottom: units(1);
  margin-top: 0;
}

.cta {
  img {
    height: units(2);
    margin-right: units(0.5);
    position: relative;
    top: units(2px);
    vertical-align: baseline;
  }
}

// Footer --------------- //

.site-footer {
  @include typeset("lang");
  clear: both;

  h3 {
    margin-top: 0;
  }

  .logo-links {
    margin-bottom: units(1);
    margin-right: units(2.5);

    @include at-media("tablet") {
      float: left;
      margin-bottom: 0;
    }

    img {
      width: units(4);
    }

    a {
      @include media-link;
    }
  }

  .usa-footer__secondary-section {
    @include u-padding-bottom(0);
    @include u-padding-top(2);
    background-color: color("base-lightest");

    @include at-media("tablet") {
      padding-top: units(4);
    }
  }
}

.footer-main-img {
  display: none;
  max-width: units("card");

  @include at-media("tablet") {
    display: block;
    margin: 0;
    width: 100%;
  }
}

.footer-content {
  // margin-bottom: units(5);
  .top-logo {
    width: 140px;
  }
  h3 {
    font-size: 14px;
    margin: 0;
    @include at-media('tablet') {
        font-size: 12px;
        line-height: 14.4px;
      }
      @include at-media('desktop') {
        font-size: 16px;
        line-height: 19.2px;
        padding-block-end: 24px;
      }
  }
  p {
    font-size: 14px;
    @include at-media('tablet') {
      margin-inline-start: 8px;
      font-size: 12px;
      line-height: 14.4px;
    }
    @include at-media('desktop') {
      font-size: 16px;
      line-height: 19.2px;
    }
  }

  ul li {

    @include at-media ('tablet') {
        margin-block-end: 8px;
      }

      @include at-media('desktop') {
        margin-block-end: 13px;
      }
  }

  ul li a {
    font-size: 14px;
    @include at-media ('tablet') {
    font-size: 12px;
    line-height: 14.4px;
    }
    @include at-media('desktop') {
      font-size: 16px;
      line-height: 19.2px;
    }
  }

  .subsection-subscribe {
    p {
      padding-block-start:37px;
      padding-block-end: 24px;
      @include at-media('desktop') {
          padding-block-start: 23px;
          padding-block-end: 22px;
        }
    }
  }

  .site-button {
    font-size: 14px;
    @include at-media('tablet') {
      font-size: 12px;
    }
    @include at-media('desktop') {
      font-size: 16px;
      line-height: 19.2px;
    }
  }
}

.roles-downloads a {
  font-size: 12px;
}

.semi-cta {
  border: 1px black solid;
  // border-radius: 130px 0 0 130px;
  padding: 50px 25px;
  min-height: 108px;

  @include at-media('tablet') {
    width: 100%;
  }

  &.radius-left-pill {
    border-right-style: none;
  }

  a {
   font-size: 12px;
   line-height: 17px;
  @include at-media('tablet') {
      font-size: 14px;
    }
  @include at-media('desktop') {
      font-size: 24px;
      line-height: 24px;

    }
  }
  .page-about & {
    padding: 0;
  }
}

.footer-contact-links {
  @include grid-gap(2);

  h4,
  a {
    @include u-font("lang", "2xs");
  }

  h4 {
    font-weight: font-weight("normal");
    margin-bottom: 0;
    margin-top: units(2px);
  }

  img {
    width: units(5);
  }

  .usa-media-block {
    clear: both;
    line-height: line-height("lang", 3);
    margin-top: units(2.5);

    @include at-media("tablet") {
      margin-top: 0;
    }
  }

  @include at-media("tablet") {
    margin-top: units(4);
  }
}

.footer-section-bottom {
  font-size: 10px;
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  @include at-media('tablet') {
    font-size: 12px;
  }

  @include at-media('desktop') {
    font-size: 16px;
  }
}

// Pattern Preview Boxes -------- //

.preview {
  @include clearfix;
  @include u-margin-y(2);
  background-color: color("white");
  padding: units(2);
  border: units(1px) solid color("base-lightest");

  .usa-dark-background {
    @include u-padding-x(1);
    display: inline-block;
  }

  > *:first-child {
    margin-top: 0;
  }
}

.preview-no_border {
  border: 0;
  margin-bottom: units(2.5);
  margin-top: 0;
  padding: 0;
}

// Custom preview box styles

.preview-lists {
  .usa-heading-alt {
    margin-top: 0;
  }
}

.preview-dropdown {
  padding-top: 0;
}

// Custom styles to illustrate invisible grid for web design standards

.page-grids {
  .preview {
    border: 0;
    margin-bottom: units(2.5);
    margin-top: 0;
    padding: 0;

    > *:first-child {
      margin-top: units(2);
    }
  }
}

.grid-example {
  .usa-grid {
    background: color($theme-color-primary-lighter);

    > * {
      @include u-margin-y(0.5);
      color: color("white");
      padding: units(1);
      background: color($theme-color-primary-dark);
    }
  }

  .usa-width-one-twelfth {
    @include at-media("tablet") {
      @include u-padding-x(0);
    }
  }
}

.grid-example-blank {
  .usa-grid {
    text-align: center;
  }
}

.grid-text {
  .usa-grid {
    background: color($theme-color-primary-dark);

    > * {
      background: color($theme-color-primary-lighter);
      color: color("black");
    }

    h3 {
      margin-top: 0;
    }

    p {
      margin-bottom: 0;
    }
  }
}

h3 + .button_wrapper {
  margin-top: units(-0.5);
}

.button_wrapper {
  clear: both;
  display: block;
  margin-left: units(-1);
  padding: 0 units(1);

  @include at-media("mobile-lg") {
    display: table;
  }

  button:last-child {
    margin-right: 0;
  }
}

.button_wrapper-dark {
  background: color("base-darker");
}

.usa-heading {
  color: color($site-primary-dark);
}

// Color background helpers

.usa-color-primary {
  background-color: color($site-primary);
}

.usa-color-primary-darker {
  background-color: color($site-primary-dark);
}

.usa-color-primary-darkest {
  background-color: color($site-primary-darker);
}

.usa-color-base {
  background-color: color("base-darkest");
}

.usa-color-gray-dark {
  background-color: color("base-darker");
}

.usa-color-gray-light {
  background-color: color("base-light");
}

.usa-color-white {
  background-color: color("white");
  box-shadow: inset 0 0 0 1px color("gray-cool-60");
}

.usa-color-primary-alt {
  background-color: color("accent-cool");
}

.usa-color-primary-alt-dark {
  background-color: color("accent-cool-dark");
}

.usa-color-primary-alt-darkest {
  background-color: color("accent-cool-darker");
}

.usa-color-primary-alt-light {
  background-color: color("accent-cool-light");
}

.usa-color-primary-alt-lightest {
  background-color: color("accent-cool-lighter");
}

.usa-color-secondary {
  background-color: color("secondary");
}

.usa-color-secondary-dark {
  background-color: color("secondary-dark");
}

.usa-color-secondary-darkest {
  background-color: color("secondary-darker");
}

.usa-color-secondary-light {
  background-color: color("secondary-light");
}

.usa-color-secondary-lightest {
  background-color: color("secondary-lighter");
}

.usa-color-gray {
  background-color: color("gray-cool-60");
}

.usa-color-gray-lighter {
  background-color: color("gray-cool-10");
}

.usa-color-gray-lightest {
  background-color: color("gray-cool-5");
}

.usa-color-gray-warm-dark {
  background-color: color("gray-warm-70");
}

.usa-color-gray-warm-light {
  background-color: color("gray-warm-10");
}

.usa-color-gray-cool-light {
  background-color: color("blue-warm-5");
}

.usa-color-gold {
  background-color: color("gold-20v");
}

.usa-color-gold-light {
  background-color: color("gold-10v");
}

.usa-color-gold-lighter {
  background-color: color("yellow-10");
}

.usa-color-gold-lightest {
  background-color: color("gold-5v");
}

.usa-color-green {
  background-color: color("green-cool-50");
}

.usa-color-green-light {
  background-color: color("green-cool-40");
}

.usa-color-green-lighter {
  background-color: color("green-cool-20");
}

.usa-color-green-lightest {
  background-color: color("green-cool-5");
}

.usa-color-cool-blue {
  background-color: color("blue-70v");
}

.usa-color-cool-blue-light {
  background-color: color("blue-warm-50");
}

.usa-color-cool-blue-lighter {
  background-color: color("blue-warm-30");
}

.usa-color-cool-blue-lightest {
  background-color: color("blue-warm-10");
}

.usa-color-focus {
  background-color: color($theme-focus-color);
}

.usa-color-visited {
  background-color: color($theme-link-visited-color);
}

// Color text helpers

.usa-color-text-primary-darkest {
  color: color($site-primary-darker);
}

.usa-color-text-primary-darker {
  color: color($site-primary-dark);
}

.usa-color-text-primary {
  color: color($site-primary);
}

.usa-color-text-cool-blue-light {
  color: color("blue-warm-50");
}

.usa-color-text-primary-alt-darkest {
  color: color("accent-cool-darker");
}

.usa-color-text-green {
  color: color("green-cool-50");
}

.usa-color-text-visited {
  color: color($theme-link-visited-color);
}

.usa-color-text-gray-dark {
  color: color("gray-cool-80");
}

.usa-color-text-gray {
  color: color("gray-cool-60");
}

.usa-color-text-gray-warm-dark {
  color: color("gray-warm-70");
}

.usa-color-text-secondary-darkest {
  color: color("secondary-darker");
}

.usa-color-text-secondary-dark {
  color: color("secondary-dark");
}

.usa-color-text-secondary {
  color: color("secondary");
}

.usa-color-text-white {
  color: color("white");
}

.usa-heading-alt {
  border-bottom: units(1px) solid color("gray-cool-60");
  padding-bottom: units(1);
}

.usa-typography-example-font {
  padding-top: units(1);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .usa-font-example p {
    margin: 0;
  }
}

.font-example-paragraph {
  @include at-media("tablet") {
    max-width: units("card-lg");
    width: 100%;
  }
}

.usa-typography-example {
  .usa-monospace {
    font-size: font-size("mono", "3xs");
    margin-bottom: units(4);
    margin-top: 0;
  }
}

.usa-monospace {
  font-family: font-family("mono");
}

// Code samples ---------------------- //

code,
pre {
  @include u-font("mono", "3xs");
  // TODO: add more black-transparent values (1-5)
  background-color: rgba(0, 0, 0, 0.05);
  font-weight: fw("normal");
}

pre {
  padding: units(2);

  code {
    padding: 0;
    background-color: transparent;

    &::before,
    &::after {
      display: none;
    }
  }

  &.lineno {
    user-select: none;
  }
}

a.token {
  @include u-padding-x(0.5);
  @include u-padding-y("05");
  @include u-font("code", "3xs");
  @include u-text("no-underline");
  background-color: color($site-primary-lighter);
  border: units("1px") solid color($site-primary-lighter);
  border-radius: radius("sm");
  color: color($site-ink);
  display: inline-block;
  line-height: line-height("mono", 1);

  &:visited {
    color: color($site-ink);
  }

  &:hover,
  &:hover:visited {
    background-color: color($site-primary-vivid);
    border-color: color($site-primary-vivid);
    color: color("white");
  }

  &:active,
  &:active:visited {
    background-color: color($site-ink);
    border-color: color($site-ink);
    color: color("white");
  }
}

code {
  @include u-padding-y(2px);

  &::before,
  &::after {
    letter-spacing: letter-spacing("ls-neg-3");
    content: "\00a0";
  }
}

// Code snippet boxes ----------------- //

.site-accordion-code {
  margin-bottom: units(1);

  pre,
  code {
    @include typeset("mono", "2xs", 4);
    background: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
  }

  .usa-unstyled-list {
    @include unstyled-list;
  }
}

[class*="language-"] {
  color: color("base-darkest");

  // Custom code sample colors
  .nt,
  .token.property,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: color("accent-cool-darker");
  }

  .na,
  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: color("secondary");
  }

  .s,
  .token.atrule,
  .token.attr-value,
  .token.keyword {
    color: color("green-cool-50");
  }

  .token.punctuation {
    color: color("accent-cool-darker");
  }

  .c {
    color: color("gray-cool-60");
  }
}

.alignment-example {
  border-left: units(2px) solid color($site-primary-dark);
  padding-left: units(1);
}

.help-text {
  border-top: units(2px) solid color($site-primary-dark);
  color: color($site-primary-dark);
  font-size: $theme-root-font-size;
  padding-top: units(1);
  text-align: center;
}

.text-tiny {
  margin-bottom: 0;
  margin-top: units(0.5);

  &:first-child {
    margin-top: 0;
  }
}

.typography-sans-intro {
  .text-huge {
    @include typeset("sans", 20, 1);
  }

  .text-tiny {
    @include typeset("sans", "2xs", 1);
  }
}

.typography-serif-intro {
  .text-huge {
    @include typeset("serif", 20, 1);
  }

  .text-tiny {
    @include typeset("serif", "2xs", 1);
  }
}

.typography-lang-intro {
  .text-huge {
    @include typeset("lang", 20, 1);
  }

  .text-tiny {
    @include typeset("lang", "2xs", 1);
  }
}

.typography-mono-intro {
  .text-huge {
    @include typeset("mono", 20, 1);
  }

  .text-tiny {
    @include typeset("mono", "2xs", 1);
  }
}

// Alt font styles

@mixin font-lead-alt {
  font-size: font-size("lang", "xs");
  font-weight: font-weight("normal");
}

.serif-robust {
  .usa-display-alt {
    font-size: font-size("lang", $theme-h1-font-size);
    font-weight: font-weight("light");
  }

  .usa-font-lead {
    font-weight: font-weight("light");

    .usa-font-lead-alt {
      @include font-lead-alt;
    }
  }

  &.serif-body {
    .usa-font-example {
      p {
        @include typeset("serif", "xs", 3);
      }

      // scss-lint:disable SelectorDepth, NestingDepth
      .usa-font-lead {
        font-size: font-size("lang", "lg");

        .usa-font-lead-alt {
          @include font-lead-alt;
        }
      }
    }
  }
}

.sans-style {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: font-family("sans");
  }

  h1 {
    font-size: font-size("sans", 15);
  }

  h2 {
    font-size: font-size("sans", 12);
    line-height: 1.2;
  }

  h3 {
    font-size: font-size("sans", 11);
  }

  h4 {
    font-size: font-size("sans", 8);
  }

  h5 {
    font-size: font-size("sans", 6);
  }

  h6 {
    font-size: font-size("sans", 3);
  }

  .usa-display {
    font-size: font-size("sans", 16);
  }

  .usa-display-alt {
    font-size: font-size("sans", 15);
    font-weight: font-weight("light");
  }

  .usa-font-lead {
    @include typeset("sans", 10, 4);
    font-weight: font-weight("light");
  }

  &.serif-body {
    .usa-font-example {
      p {
        @include typeset("serif", 5, 3);
      }

      .usa-font-lead {
        @include typeset("sans", 8, 4);
        font-weight: font-weight("light");
      }
    }
  }
}

.serif-sans-minor {
  h6 {
    font-family: font-family("sans");
  }
}

.site-accordion-docs {
  margin-bottom: units(7);
}

// External link styles
.explicit-external-link {
  @include external-link();
}

.usa-button,
.link-download,
.media_link {
  &#{$external-href}::after {
    display: none;
  }
}

.logo-links,
.usa-disclaimer-stage {
  #{$external-href} {
    &::after {
      display: none;
    }
  }
}

.split-background-primary {
  background: linear-gradient(color("primary"),color("primary"))0 0/100% 40% no-repeat;
}

.split-background-dark {
  background: linear-gradient(color("base-darkest"),color("base-darkest"))0 0/100% 40% no-repeat;
}

// ========================================
// 3. Custom page styles
// ----------------------------------------


.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;

  &.fixed-width-modal {
    @include u-maxw('mobile-lg');
    @include at-media('tablet') {
      @include u-maxw('tablet-lg');
    }
  }
}

.modal-grid {
  margin-top: -2.6rem;
  @include grid-row;
  flex-direction: column;
  .modal-column {
    @include grid-col(12);
  }

  @include at-media('tablet'){
    flex-direction: row;
    .modal-column {
      @include grid-col(6);
    }
  }
}

.usa-modal .modal-column {
  z-index: 1;
}

.usa-modal .usa-modal__close {
  z-index: 2;
}

.modal-column > div, .modal-column > header {
  margin-left: 0 !important;
}

.modal-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* The Close Button */
.close {
  color: color("white");
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  background-color: color("secondary");
  padding: 5px;
  margin: 0;
  z-index: 100;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-inactive {
  display: none !important;
}

.modal-active {
  display: block;
}

.pc-inactive {
  display: none !important;
}


.usa-media-block__body {
  // scss-lint:disable SelectorDepth
  color: color("white");
  @include at-media("desktop") {
    .border-right {
      border-right: solid 1px color("base-lightest");
    }
  }
}

.usa-media-block__footer {
  a {
    text-decoration: none;
    color: color("white");
  }

  h3 {
    color: color("white");
  }
}

.usa-media-block__body-outlined {
  // scss-lint:disable SelectorDepth
  color: color("white");
  padding: units(6);
  border: solid 1px rgba(255, 255, 255, 0.8);
  &--secondary {
    @include u-padding(6);
    @include u-color('black');
    border: 1px solid black;
  }
}

.usa-media-block__inline-items {
  // scss-lint:disable SelectorDepth
  display: flex;

 .inline-number {
   display: inline-block;
   .page-toolkit & {
    display: flex;
    justify-content: center;
    align-items: flex-start;
   }
   p {
     color: color("white");
     background-color: color("secondary");
     font-size: font-size("lang", "xs");
     padding-left: units(1);
    .page-toolkit & {
          border-radius: 50%;
          width: 26px;
          padding-left: 0   ;
      }
   }
 }

 .inline-text {
   display: inline-block;
   padding-left: units(3);
 }

}

.usa-media-block__background-image-landscape {

  .content {
    width: 100%;
    top: 0;
    color: color("white");
    padding: units(1) units(5) units(1) units(3);
    margin-right: units(2);

    a {
      text-decoration: none;
      padding-top: units(4);
      padding-bottom: units(4);
      color: color("white");
      &:visited {
        color: color("white");
      }
      &:hover {
        color: color("base-light");
        text-decoration: underline;
      }
    }
  }

  .overlay{
    .overlay-image {
      width: 100%;
      @include at-media("mobile") {
        height: 475px;
      }
      padding: 0;
      padding-left: units(-2);
    }

    picture + .overlay-content {
      position: absolute;
      bottom: units(5);
      background-color: color("base-darkest");
      color: color("white");
      padding: units(3) units(5);
    }

    @include at-media("desktop")  {
      picture + .overlay-content {
        width: 65%;
      }
    }
  }


  img + .content-grid-one-third {
    height: 300px;
    width: 85%;
    position: absolute;
    bottom: 7px;
    background-color: color("base-darkest");
    color: color("white");
    padding: units(2);
    }
}




// Homepage styles --------------------- //

.page-home {


  // Override for 4x1 media grid when it collapses to 2x2
  @media screen and (max-width: units("desktop")) and (min-width: units("tablet")) {
    // scss-lint:disable SelectorDepth
    .usa-graphic-list
      .usa-graphic-list__row
      .usa-media-block:nth-child(-n + 2) {
      margin-bottom: units(8);
    }
  }

  .usa-footer__secondary-section {
    background-color: color("base-lighter");
  }
}

.page-curated-datasets {
  @include u-bg('primary-vivid');
}

.hero {
  @include at-media("tablet-lg") {
    background-image: url("#{$site-image-path}/home/hero.webp");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right units(5) center;
  }
}

.hero--heading {
  font-size: font-size("lang", "xl");

  @include at-media("tablet") {
    font-size: font-size("lang", "2xl");
  }
}

.hero--content {
  font-size: font-size("lang", "lg");
}

.showcase {
  img {
    @include u-shadow(1);
  }

  a {
    display: inline-block;
    font-size: font-size("lang", "md");
    font-weight: fw("bold");
    margin-bottom: units(4);
    margin-top: units(1);

    @include at-media("tablet") {
      margin-bottom: 0;
    }
  }
}

.font-display {
  font-size: font-size("lang", "3xl");
}

.background-hero-image-home {
  @include at-media("desktop") {
    background-image: url('../img/photos/home/hero.webp');
    height: 600px;
    background-position: right;
    background-repeat: no-repeat;
    background-size:contain;
  }
}

.background-hero-image-coil {
  @include at-media("desktop") {
    background-image: url('../img/photos/coil/coil-hero.webp');
    height: 600px;
    background-position: right;
    background-repeat: no-repeat;
    background-size:contain;
  }
}

.background-hero-image-hcd {
  @include at-media("desktop") {
    background-image: url('../img/photos/hcd/HCD-Training-Photos-24.webp');
    height: 500px;
    background-position: right 0px top 100px;
    background-repeat: no-repeat;
    background-size:contain;
  }
}

.sprint-hero__image {
  @include u-bg('primary');
  display: none;
  @include at-media('tablet'){
    display: flex;
    @include grid-col(1);
  }
}

.sprint-hero__callout {
  @include u-text('italic', 'base-darkest');
  @include u-bg('base-lightest');
  @include u-padding(2);
  @include u-border(2px, 'secondary');
  @include u-margin-top(2);
}

.statistic-border-right {
  @include at-media("desktop") {
    border-right: solid 1px color("base-darker");
  }
}

.translucent-bottom-border {
  @include at-media("desktop") {
    border-bottom: solid 1px color("base-lighter");
  }
}

// Summit page
.page-annual-innovation-event {
  background: color("base-darkest");

  .banner-background {
    background: url("../img/photos/summit/desktop-hero-background.png") no-repeat 0 0;
    background-size: cover;
  }
  .impact {
    background: url("../img/photos/summit/mobile-impactnumbers-background.png") no-repeat 0 0;
    background-size: cover;
    font-size: 14px;
    font-weight: 600;
    padding-top:48px;
    padding-bottom:40px;
    h2 {
      line-height: 3.5px;
    }
    .impact-numbers-container {
      display: flex;
      flex-direction: column;
    }
    p {
      width: min-content;
      text-align: center;
      line-height: 18px;
    }
    @include at-media('tablet') {
      background: url("../img/photos/summit/tablet-impactnumbers-background.png") no-repeat 0 0;
      background-size: cover;
      font-size: 20px;
      padding-top:68px;
      padding-bottom:136px;
      h2 {
        line-height: 7px;
      }
      .impact-numbers-container {
        flex-direction: row;
        justify-content: space-around;
      }
      p {
        line-height: 24px;
      }
    }

    @include at-media('desktop') {
      font-size: 32px;
      padding-top: 120px;
      padding-bottom: 216px;
      h2 {
        line-height: 11px;
      }
      p {
        line-height: 36px;
      }
    }
  }
  .past-events {
    background: url("../img/photos/summit/mobile-pastevents-background.png") no-repeat 0 0;
    background-size: cover;
    @include at-media('tablet') {
      background: url("../img/photos/summit/desktop-pastevents-background.png") no-repeat 0 0;
      background-size: cover;

    }
  }

  h1 {
    font-size: 16px;
    line-height: 22px;

    @include at-media("tablet") {
      font-size: 24px;
      line-height: 28px;
    }

    @include at-media("desktop") {
      font-size: 32px;
      line-height: 42px;
    }
  }

  .usa-hero__subtitle {
    font-size: 14px;
    line-height: 16px;

    @include at-media("tablet") {
      font-size: 14px;
      line-height: 20px;
    }

    @include at-media("desktop") {
      font-size: 24px;
      line-height: 30px;
    }
  }

  h2 {
  font-size: 14px;
  line-height: 21px;

    @include at-media("tablet") {
      font-size: 24px;
      line-height: 22px;
    }

    @include at-media("desktop") {
      font-size: 32px;
      line-height: 36px;
    }
  }

  h2.text-normal {
    margin-top: 0px;
    >p:nth-child(1) {
        margin-top: 0px;
      }
  }

  .cta-one, .cta-one>p a.site-cta {
    font-size: 12px;
    line-height: 16px;

    @include at-media("tablet") {
      font-size: 14px;
      line-height: 18px;
    }

    @include at-media("desktop") {
      font-size: 24px;
      line-height: 30px;
    }

    >p {
      margin-bottom: 0px;
    }
  }

  .video-section-container {
    // put this in here
    // flex-column flex-no-wrap flex-align-center flex-justify-center tablet: flex-row tablet:flex-wrap tablet:flex-align-start
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    @include at-media("tablet") {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: start;
    }
  }

  .video-section {
    // max-width: 375px;
    width: 233px;
    .iframe-content div {
      min-width: 125px;
    }
    @include at-media("tablet") {
      width: 247px;
    }
    @include at-media("desktop") {
      width: 392px;
    }
  }
  div.video-section-text-container {
      font-size: 10px;
      line-height: 12.6px;
      padding: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h3 {
        padding: 0;
        margin:0;
      }
      @include at-media("tablet") {
        max-width: 247px;
        height: 144px;
        padding: 24px;
        }

      @include at-media("desktop") {
        max-width: 392px;
        height: 230px;
        padding: 32px;
        h3 {
          font-size: 16px;
          line-height: 24px;
        }

        p {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
    section.highlight-video-section div.video-section-text-container {
      height: auto;
      h3 {
        @include at-media("desktop") {
          font-size: 24px;
          line-height: 24px;
        }
      }
    }
  }


// Logo Garden

.logo-garden-container {
  display: none;
  @include at-media("desktop") {
    margin-top: units(6);
    opacity: 0.5;
    width: units('desktop');
    display: flex;
    justify-content: space-between;
  }
}

.logo-garden-mobile {
  opacity: 0.5;
  @include at-media("desktop") {
    display: none;
  }
}

.logo-garden-item {
  max-height: 30px;
  max-width: auto;
  display: inline-block;
}

.logo-garden-large {
  max-height: 100px;
  max-width: auto;
  display: inline-block;
  margin-left: 40px;
  margin-right: 40px;
}


// process page header overlay banner

.header-overlay {
  width: 60%;
  position: absolute;
  top: units(5);
  background-color: color("base-darkest");
  color: color("white");
}


// Fancy Horizontal Rule

.homepage-rule {
  @include u-margin-y(0);
  border: 0;
  height: units(2);
  width: 100%;
  position: relative;

  &.center-diamond {
    background: none;
    text-align: center;

    &::before {
      content: "\00a0";
      display: inline-block;
      background: color($site-primary);
      height: units(1px);
      position: relative;
      top: units(1);
      width: 100%;
    }

    &::after {
      content: "◆";
      display: inline-block;
      position: relative;
      top: units(-4);
      font-size: 1.125rem; // TODO: do I care about this? (18px)
      padding: 0 units(1);
      color: color("secondary");
      background: color($site-background-color);
    }
  }
}

.homepage-process__button-text {
  padding-left: 3rem;
  text-indent: -2.8rem;
  line-height: 1.3;
}

.page-ui-components {
  .usa-font-lead {
    &:first-of-type {
      margin-top: units(4);
    }
  }
}

.page-design-principles {
  @include at-media("tablet") {
    .main-content .usa-grid-full {
      margin-bottom: units(4);
      margin-top: units(5);
    }
  }

  img {
    max-width: units("card");
  }
}

.link-download {
  display: table;

  &::before {
    @include u-square(1.5);
    background: url("#{$image-path}/icon-download.png") no-repeat 0 0;
    background: url("#{$image-path}/icon-download.svg") no-repeat 0 0;
    background-size: 100%;
    content: "";
    display: inline-block;
    margin-bottom: 0;
    margin-right: units(1.5);
  }
}

.link-download-subtext {
  margin-left: units(3);
}

.link_group-download {
  .link-download {
    margin-bottom: units(1);
  }
}


// Products Showcase Page

#all-products {
  padding-top: 4rem;
}

#product-search-form {
  @include u-width('mobile');

  .usa-input {
    border-color: color("base-light");
  }
}

.product-filter-form-desktop {
  visibility: hidden;
  @include at-media("desktop") {
    visibility: visible;
  }
}

.product-filter-form-mobile {
  @include at-media("desktop") {
    visibility: hidden;
  }
}

.product-filter__container {
  @include u-margin-top(1, !important);
  &:nth-child(3) {
    button {
      padding-bottom: .6rem;
      padding-top: .7rem;
    }
  }
}

.product-filter__button {
  // @include u-padding-left(1, !important);
  @include u-margin-top(0);
  @include u-border('1px', 'solid', 'black');
  @include u-bg('transparent');
  &:hover{
    @include u-bg('base-lightest');
  }
  &:active {
    @include u-bg('base-lighter');
  }
}

.usa-accordion {
  opacity: 1;
}

.usa-accordion__button {
  background-image: url(../img/icons/remove-24px-icon-black.svg);
  background-repeat: no-repeat;
  &.light {
    background-image: url(../img/icons/remove-24px-icon-white.svg);
    background-repeat: no-repeat;
  }
  &.usa-banner__button {
    background-image: none;
  }
}

.usa-accordion__button[aria-expanded="false"] {
  background-image: url(../img/icons/add-24px-icon-black.svg);
  background-repeat: no-repeat;
  &.light {
    background-image: url(../img/icons/add-24px-icon-white.svg);
    background-repeat: no-repeat;
  }
  &.usa-banner__button {
    background-image: none;
  }
}


.usa-accordion__button-alt {
  background-image: none;
  @include at-media("tablet") {
    @include add-icon(
      "angle-arrow-down-primary",
      "after",
      1,
      1,
      2px,
      "no-hover"
    );
  }
  &[aria-expanded="false"] {
    background-image: none;
  }
  &[aria-expanded="true"] {
    @include at-media("tablet") {
      @include add-icon(
        "angle-arrow-up-primary",
        "after",
        1,
        1,
        2px,
        "no-hover"
      );
    }
  }
}

.usa-accordion__button-toolkit {
  background-image: none;
  @include at-media("tablet") {
    @include add-icon(
      "angle-arrow-down-primary",
      "after",
      1,
      1,
      2px,
      "no-hover"
    );
  }
  &[aria-expanded="false"] {
    background-image: none;
  }
  &[aria-expanded="true"] {
    @include at-media("tablet") {
      @include add-icon(
        "angle-arrow-up-primary",
        "after",
        1,
        1,
        2px,
        "no-hover"
      );
    }
  }
}

.background-right {
  background-position: right 5% center;
}

.dropdown-menu {
  @include u-width('mobile');
  @include u-height('card');
  padding: units(2);
  overflow: auto;
  border: 1px solid color("base-light");
  background-color: color("white");
  opacity: 1;
}

.scrolled-input {
  margin-bottom: -1.5em;
  &:first-child {
    margin-top: -2em;
  }
  &:last-child {
    margin-bottom: 0;
  }
  input {
    position: static;
    opacity: 0;
    margin-top: 3rem;
  }
  label {
    margin-top: -1.5rem;
  }
}

.yr-btn {
  font-size: font-size("mono", "2xs");
  border: solid color("white") 1px;
  color: color("white");
  margin: 0;
  padding: units(1) units(1);
  -o-transition:.2s;
  -ms-transition:.2s;
  -moz-transition:.2s;
  -webkit-transition:.2s;
  transition:.2s;
  max-width: 100px;
  white-space: nowrap;
  overflow-x: hidden;
  @include at-media('mobile-lg') {
    max-width: none;
    padding: units(1) units(2);
  }

  &.dark-btn {
    border: solid 2px color('base-darkest');
    color: color('base-darkest');
    border-left-width: 1px;
    border-right-width: 1px;

  }
}

.yr-btn:hover, .yr-btn-active {
  color: color("base-darkest");
  background-color: color("white");

  &.dark-btn {
    color: color('white');
    background-color: color('base-darkest');
  }
}

.year-wrapper {
  border: solid color("white") 1px;
  float: right;
  border-radius: 5px;
  cursor: pointer;
}

.ps-inactive {
  display: none !important;
}


// Sprints page
.tab-row {
  width: max-content;
  margin: units(5) auto;

}

.tab-wrapper {
  cursor: pointer;

  &:first-child * {
    border-left-width: 2px;
  }
  &:last-child * {
    border-right-width: 2px;
  }
}


// Layout grid page
.docs-grid-example {
  @include u-font("mono", 4);

  .grid-row {
    margin-top: units(1);
  }

  [class*="grid-col"] {
    background-color: color($site-primary-lighter);
    text-align: center;
  }

  .grid-row:not([class*="grid-gap"]) {
    [class*="grid-col"] {
      border: units(1px) solid;
      padding: units(2);
    }
  }

  // scss-lint:disable SelectorDepth
  [class*="grid-gap"] {
    [class*="grid-col"] > * {
      background-color: color("white");
      border: units(1px) solid;
      padding: units(2);
    }
  }
}

// Utilities CSS --------------------- //

.is-inverse {
  @include add-knockout-font-smoothing;
}

.page-content {
  h3 {
    @include u-font-size("lang", 10);
    @include u-text("normal");
    @include u-line-height("lang", 3);
    @include at-media("tablet-lg") {
      @include u-font-size("lang", 15);
    }
  }
}

// * * * * * * ========================= *

.docs-text {
  @include u-font("lang", 6);
  @include u-margin-bottom(1);
  @include u-margin-top(4);
  @include u-measure($site-measure);
  @include u-text("normal");
}

strong {
  @include u-text("bold");
}

.docs-inline-code {
  @include u-bg("gray-warm-4");
  @include u-font("mono", 4);
  @include u-padding-x("05");
  @include u-padding-y(2px);
  @include u-radius("sm");
}

.property {
  $theme-font: "mono";
  @include u-border(1px);
  @include u-color("red-warm-50v");
  @include u-font($theme-font, 1);
  @include u-line-height($theme-font, 1);
  @include u-padding-x("05");
  @include u-padding-y(2px);
  @include u-radius("sm");
  @include u-text(
    "normal",
    "no-wrap",
    "no-underline",
    "uppercase",
    "no-italic",
    "ls-2"
  );
}

.prose-graf-intro {
  $theme-font: "lang";
  @include u-font($theme-font, 2);
  @include u-text("bold", "uppercase", "ls-2");
  @include at-media("desktop-lg") {
    @include u-font-size($theme-font, 3);
  }
}

.text-color .example {
  @include u-border-bottom(1px, "base-lighter");
  @include u-margin-y(0.5);
  @include u-padding-y(0.5);
}

.output-list {
  @include grid-row;
  @include u-margin-top(0);
  @include u-width("full");

  .output-utility,
  .output-css,
  .output-variable {
    @include u-border-bottom(1px);
    @include u-border-bottom("base-light");
    @include u-display(flex);
    @include u-flex("align-center");
    @include u-margin(0);
    @include u-minh(4);
    @include u-padding-y(0.5);
  }

  .output-utility {
    @include grid-col(4);
    @include u-font("lang", "2xs");
    @include u-padding-right(2);
  }

  .output-css {
    @include grid-col(6);
    @include u-font("mono", "2xs");
    @include u-padding-right(2);

    .output-token {
      @include u-display(inline-block);
      @include u-font("mono", "2xs");
    }
  }

  .output-variable {
    @include grid-col(2);
    @include u-font("mono", "2xs");
    @include u-text("light");
  }
}

.output-token {
  @include u-bg("white");
  @include u-border(1px, "gray-10");
  @include u-font("mono", 4);
  @include u-padding-x(0.5);
  @include u-padding-y(2px);
  @include u-radius("sm");
  @include u-text("normal");
}

.output-rule {
  @include u-display(block);

  + .output-rule {
    @include u-margin-top(0.5);
  }
}

.output-true {
  @include add-knockout-font-smoothing;
  @include u-bg("blue-50v");
  @include u-display("inline-block");
  @include u-padding-y(0);
  @include u-padding-x(0.5);
  @include u-radius("sm");
  @include u-text("white");
}

.output-false {
  @extend .output-true;
  @include u-bg("error");
}

// Ensure that inline lists do not have a bottom margin
li {
  &.display-inline-block {
    margin-bottom: 0;
  }
}

.page-introducing-uswds-2-0 {
  .post-content {
    img {
      @include u-maxw(mobile);
      mix-blend-mode: darken;
    }
  }
}

// * * * * * * ==========================
// Utility docs

.utilities-property-title {
  @include u-font("lang", "micro");
  @include u-margin-left(2px);
  @include u-margin-y(0);
  @include u-text("normal", "uppercase", "ls-1");
}

.utilities-properties {
}

.utilities-property {
  @include u-bg("white");
  @include u-display("inline-block");
  @include u-margin-top(0);
  @include u-padding(0.5);
  @include u-text("no-uppercase", "ls-auto");
}

.utilities-section {
  @include u-border-top(1px);
  @include u-margin-top(4);
  @include u-padding-top(1);
}

.utilities-section-title-bar {
  @include u-flex("align-center");
  @include u-margin-bottom(2);
}

.utilities-section-title {
  @include u-font("lang", 8);
  @include u-margin(0);
  @include u-text(normal);

  code {
    @include u-bg("white");
    @include u-border(1px);
    @include u-display("inline-block");
    @include u-font("mono", 6);
    @include u-margin-right(2px);
    @include u-padding-x(0);
    @include u-padding-y(2px);
    @include u-radius("md");
    @include u-text("light");
  }

  &.usa-grid-col {
    @include grid-cols(null, "auto");
  }
}

.utilities-section-helper {
  @include u-font("lang", 1);
  @include u-margin-y(0);
  @include u-maxw("none");

  &.usa-grid-col {
    @include grid-cols(null, "fill");
  }

  @include at-media($theme-site-cols-split) {
    @include u-text(right);
  }
}

.utility {
  @include u-bg("white");
  @include u-border(1px, "base-darker");
  @include u-margin-y(2);
  @include u-padding-bottom(2);
  @include u-padding-top(2);
  @include u-radius("md");
}

.utility-title-bar {
  @include u-margin-bottom(2);
  @include u-padding-x(2);

  & > .usa-grid-row {
    @include u-flex("align-start");
  }
}

.utility-name {
  &.usa-grid-col {
    @include grid-cols(null, "fill");
    max-width: 100%;
  }
}

.utility-title {
  @include u-font("lang", "md");
  @include u-margin-bottom(0.5);
  @include u-margin-top(0);
}

.utility-property {
  @include u-font("lang", "3xs");
  @include u-margin(0);
  @include u-text("uppercase", "ls-1");
}

.utility-property-code {
  @include u-font("mono", "2xs");
  @include u-text("light", "no-uppercase", "ls-auto", "red-warm-50v");
}

.utility-scope {
  @include add-list-reset;
  @include u-display("inline");
  @include u-margin(0);

  @include at-media($theme-site-cols-split) {
    @include u-text("right");
    max-width: 70%;
  }

  &.usa-grid-col {
    @include grid-cols(null, "auto");
  }
}

.utility-scope-button {
  @include u-display("inline-block");
  @include u-font-size("lang", "2xs");
  @include u-line-height("lang", 1);
  @include u-margin-right(2px);
  @include u-margin-top(0.5);
  @include u-padding(0.5);
  @include u-radius("sm");

  @include at-media("tablet") {
    @include u-font-size("lang", "xs");
    @include u-margin-right(0.5);
    @include u-margin-top(0);
    @include u-padding-y(1);
    @include u-padding-x(1.5);
    @include u-radius("md");
  }
}

.utility-scope-button-disabled {
  @extend .utility-scope-button;
  @include u-bg("white");
  @include u-border(1px, "gray-10");
  @include u-text("gray-20", strike);
}

.utility-scope-button-active {
  @include u-display(inline);

  a {
    @extend .utility-scope-button;
    @include add-knockout-font-smoothing;
    @include u-bg("blue-50v");
    @include u-text("white", "no-underline");

    &:hover {
      @include u-bg("gray-80");
      @include u-text("white");
    }
  }
}

.utility-examples {
  @include u-margin-x(2);
}

.utility-examples-title {
  @include u-font("lang", 4);
  @include u-padding-top(4);
  margin-bottom: 0;
  margin-top: units(1);
  border-top: units(1px) solid color("gray-10");
}

.utility-examples-helper {
  @include u-font("lang", 2);
  @include u-margin-left(1);
  @include u-text("normal", $site-ink);
}

.utility-class {
  @include u-bg("base-lightest");
  @include u-display("inline-block");
  @include u-font("mono", "2xs");
  @include u-margin(0);
  @include u-padding-y(2px);
  @include u-padding-x(0.5);
  @include u-radius("sm");
}

.utility-value {
  @extend .utility-class;
  @include u-bg("secondary-lighter");
}

.utility-value-color {
  @extend .utility-class;
  @include u-bg("white");
  @include u-border(1px, "gray-10");
  @include u-margin-top(0);
  @include u-text("light", "no-wrap", "lowercase");
}

.utility-value-color-chip {
  @include u-circle(1.5);
  @include u-display("inline-block");
  @include u-margin-right(0.5);
  @include u-text("middle");
}

.utility-example-container {
  @include u-border-top(1px, "gray-10");
  @include u-margin-top(1);
  @include u-margin-bottom(0);
  @include u-maxw(none);
  @include u-padding-top(1);
  overflow: hidden;

  & > .usa-grid-row {
    @include grid-gap("md");
    @include u-flex("row", "align-center");
  }
}

.utility-example {
  max-width: 70%;

  &.usa-grid-col {
    @include grid-col("auto");
  }
}

.utility-example-class {
  &.usa-grid-col {
    @include grid-col("fill");
  }
}

.utility-example-value {
  &.usa-grid-col {
    @include grid-col("auto");
  }
}

.utilities-output-heading {
  @include u-border-bottom(1px, "gray-10");
  @include u-font("lang", 1);
  @include u-margin-top(2);
  @include u-padding-bottom(0.5);
  @include u-text("bold");
}

.utility-code-sample {
  @include u-bg("base-lighter");
  @include u-line-height("mono", 3);
  @include u-margin-x(0);
  @include u-margin-y(1);
  @include u-radius("md");
  @include u-text("pre-wrap");
}

.utility-note {
  @include u-bg("gold-20v");
  @include u-font("lang", "xs");
  @include u-line-height("lang", 5);
  @include u-margin-y(2);
  @include u-measure(none);
  @include u-padding(2);
  @include u-radius("md");
  @include u-text("black-transparent-80");

  & + .utility-examples-title {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }

  a {
    color: color("ink");
  }
}

.utility-text {
  @include u-font("lang", "xs");
  @include u-line-height("lang", 5);
  @include u-measure(5);

  + .utility-text {
    @include u-margin-top(1);
  }
}

p + .usa-accordion,
h2 + div:not(.usa-accordion__content),
h3 + div:not(.usa-accordion__content),
h4 + div:not(.usa-accordion__content),
h5 + div:not(.usa-accordion__content),
h6 + div:not(.usa-accordion__content) {
  margin-top: units(2);
}

.sidenav a {
  padding-right: units(0.5);
}

.default-container {
  @include clearfix;
}

.site-font-lead {
  @include typeset("lang", "lg", 5);
  @include u-measure(3);
  color: color($site-ink);
  font-weight: fw("light");

  & + p {
    margin-top: units(4);
  }
}



.site-docs-card-link {
  border-top: units("05") solid color($site-base-transparent-light);
  padding-top: units("105");

  &:hover {
    @include u-border-color("primary");

    a {
      color: black;
      text-decoration-color: color("base-lighter");
    }
  }
}

.block-link::before {
  @include u-pin("all");
  content: "";
}

@mixin inner-border($width, $theme-color) {
  box-shadow: inset 0 0 0 units($width) color($theme-color);
}

.usa-button-list.site-button-list {
  .usa-button {
    font-size: font-size("lang", "xs");
    margin: 0;
    width: 100%;
    @include at-media("desktop") {
      width: auto;
    }
  }

  li + li {
    .usa-button {
      margin-top: units(0.5);
      @include at-media("desktop") {
        margin-left: units(2px);
        margin-top: 0;
      }
    }
  }
}

.usa-nav__inner {
  margin-bottom: 0;
  color: color("white");

  .usa-button {
    &:hover {
      background-color: color("blue-warm-60v");
    }
  }

  .usa-button.site-button-version {
    @include no-knockout-font-smoothing;
    color: color($site-ink);
    background-color: color($site-background-color);

    &:visited {
      color: color($site-ink);
    }

    &:hover {
      background-color: color("yellow-20v");
    }
  }

  .usa-nav__link {
    color: color("white");
    &:hover {
      color: color("primary-light");
    }

    &:visited {
      color: color("primary-light");
    }
  }

  .usa-button.site-button-outline-secondary {
    @include inner-border($site-border-width, "primary");
    background-color: color("transparent");
    color: color("primary");

    &:visited {
      color: color("primary");
    }

    &:hover {
      @include inner-border($site-border-width, "blue-warm-60v");
      background-color: transparent;
      color: color("blue-warm-60v");
    }
  }
}

.site-header-dark {
  .site-header-navbar {
    background-color: color("base-darkest");
  }
  .site-logo {
    a {
      color: color("white");
    }

    .usa-logo__text {
      &::before {
        background-image: url("../img/uswds-logo/4c-lg-on-black.png");
        background-image: url("../img/uswds-logo/4c-lg-on-black.svg");
      }
    }
  }

  .usa-button.site-button-outline-secondary {
    @include inner-border($site-border-width, "white-transparent-70");
    background-color: color("transparent");
    color: color("white-transparent-70");

    &:hover {
      @include inner-border($site-border-width, "white");
      background-color: transparent;
      color: color("white");
    }

    @include at-media("desktop") {
      margin-right: units(4);
    }
  }
}

.site-page-title {
  @include typeset("lang", "2xl", 2);
  font-weight: fw("bold");
  color: color($site-ink);
}

.foo {
  @include u-border(0.5);
}

pre {
  white-space: pre;
}

// Responsive tables

$theme-table-responsive-expand-at: "tablet";
$theme-table-responsive-title-width: 15;
$theme-table-responsive-border-radius: "md";
$theme-table-padding-x: 2;
$theme-table-column-gap: 4;

.site-table-wrapper {
  @include at-media($theme-table-responsive-expand-at) {
    border: 1px solid;
    border-radius: radius("md");
    display: block;
    background-color: white;
  }

  .site-table-responsive {
    margin: 0;
  }

  & + .site-table-wrapper {
    margin-top: units(3);
  }
}

.site-prose .site-table-responsive,
.site-table-responsive {
  width: 100%;
  max-width: none;
  border-collapse: collapse;

  @include at-media-max($theme-table-responsive-expand-at) {
    @include typeset("lang", "2xs");
    display: block;
    width: 100%;

    caption {
      display: block;
    }

    thead {
      @include sr-only;
    }

    caption,
    tbody,
    tr,
    th,
    td,
    tfoot {
      border: 0;
      display: block;
      padding: 0;
      text-align: left;
      white-space: normal;
    }

    caption {
      font-weight: fw("bold");
      padding-bottom: units(1);
    }

    td,
    th {
      width: 100%;
      padding: units(0.5) units(0.5) units(0.5) 0;
      position: relative;
      z-index: z(100);

      &[data-title]:before {
        @include typeset("lang", 1, 2);
        @include u-padding-x(1);
        content: attr(data-title);
        flex: none;
        font-weight: fw("normal");
        margin-right: units(0.5);
        max-width: units($theme-table-responsive-title-width);
        text-align: left;
        width: 50%;
      }

      &.is-empty {
        display: none;
      }
    }

    th:not([data-title]) {
      font-weight: fw("bold");
    }

    tr {
      background-color: color("white");
      border: 1px solid;
      border-radius: radius($theme-table-responsive-border-radius);
      display: block;
      padding-bottom: units(2px);
      position: relative;

      &::after {
        @include u-pin("y");
        @include u-pin("left");
        content: "";
        background-color: color("gray-2");
        border-bottom-left-radius: radius(
          $theme-table-responsive-border-radius
        );
        border-top-left-radius: radius($theme-table-responsive-border-radius);
        display: block;
        max-width: units($theme-table-responsive-title-width);
        position: absolute;
        width: 50%;
        z-index: z(0);
      }

      &.is-empty {
        display: none;
      }

      & + tr {
        margin-top: units(1);
      }
    }

    td {
      align-items: baseline;
      background-color: transparent;
      display: flex;
      line-height: lh("lang", 4);

      &:empty {
        display: none;
      }

      & + td {
        border-top: units(1px) solid color("base-lighter");
      }

      .site-inline-swatch {
        width: 100%;
      }
    }
  }

  @include at-media($theme-table-responsive-expand-at) {
    thead {
      @include u-font("lang", 1);
    }

    caption {
      font-weight: fw("bold");
      padding-bottom: units(1);
    }

    caption,
    tbody,
    tr,
    th,
    td,
    tfoot {
      text-align: left;
    }

    th:first-child {
      padding-left: units(2);
    }

    tr,
    td {
      background-color: transparent;
    }

    th,
    td {
      @include u-padding-x($theme-table-column-gap / 2);
      @include u-padding-y(1);
      border: none;
      text-align: left;

      &:first-child {
        padding-left: units($theme-table-padding-x);
      }

      &:last-child {
        padding-right: units($theme-table-padding-x);
      }
    }

    tbody {
      tr {
        border: none;
        border-top: units(2px) solid;

        & + tr {
          border-top: units(1px) solid color("base-lighter");
        }
      }
    }
  }

  &.site-table-simple {
    p {
      margin: 0;
    }

    @include at-media($theme-table-responsive-expand-at) {
      thead {
        @include u-font("lang", 2);
      }

      th,
      td {
        @include u-padding-x($theme-table-column-gap / 2);
        @include u-padding-y(0.5);
        border: none;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      tr {
        display: flex;

        th,
        td {
          flex: flex(1);
        }
      }

      tbody {
        tr {
          border: none;
          border-top: units(1px) solid color("base-light");

          &:last-child {
            border-bottom: units(1px) solid color("base-light");
          }
        }
      }
    }
  }
}

.site-inline-swatch {
  @include u-square(4);
  border-radius: radius("sm");
  display: inline-block;
  text-align: middle;

  table & {
    display: block;
  }
}

.site-caption-swatches {
  @include typeset("lang", 10, 2);
  color: color("ink");
  margin-top: units(2);
  margin-bottom: units(1);
  padding-left: 0;

  @include at-media($theme-table-responsive-expand-at) {
    padding-left: units(2);
  }
}

.site-table-note {
  @include typeset("lang", "2xs", 4);
  padding: units(2);
  background-color: color("gold-20v");
  border-radius: radius("md");
  margin-bottom: units(1);

  @include at-media($theme-table-responsive-expand-at) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }
}

.site-title-short {
  @include at-media("mobile-lg") {
    display: none;
  }
}

.site-title {
  @include at-media-max("mobile-lg") {
    @include sr-only;
  }
}

.site-accordion-docs,
.site-accordion-code,
.site-accordion {
  @include typeset("lang");
}

.site-prose {
  @include typeset("lang");

  & > {
    p {
      max-width: measure($site-measure);
      @include typeset("lang", "sm", 4);
      line-height: 1.75;
    }

    p + * {
      margin-top: 1em;
    }

    h2,
    h3,
    h4,
    h5,
    h5 {
      @extend %header-margin;
      color: color("ink");
      margin: 0;
    }

    h3 {
      @include typeset("lang", "lg", 2);
    }

    .site-text-intro {
      @extend .site-text-intro;

      & + p {
        margin-top: units(4);
      }
    }

    header + .site-text-intro {
      margin-top: units(1);
    }

    .usa-content-list {
      padding-left: 0;
      max-width: measure($site-measure);
    }

    li,
    ul li,
    ol li {
      line-height: lh("lang", 5);
      max-width: measure($site-measure);
    }
  }
}

.site-text-intro {
  @include typeset("lang", "lg", 5);
  font-weight: fw("light");
  margin-top: units(1);
}

.usa-accordion--bordered .usa-accordion__content {
  padding-bottom: units(3);
  padding-left: units(2);
  padding-top: units(3);
}

.usa-content-list {
  @include unstyled-list;
  > li {
    border-top: 1px solid color("base-lighter");
    margin-top: units(1);
    padding-top: units(1);
    max-width: measure($site-measure);

    ul {
      list-style-type: disc;
      padding-left: units(3);
    }

    li {
      margin-top: units(0.5);
      max-width: calc(measure($site-measure) - units(3));
    }
  }

  ol {
    counter-reset: ol-counter;
    list-style: none;
    padding-left: 0;

    li {
      counter-increment: ol-counter;
      margin-top: 0.5em;
      padding-left: units(3);
      text-indent: units(-3);

      &:before {
        content: counter(ol-counter) ". ";
        display: inline-block;
        font-size: fs("lang", "3xs");
        font-weight: fw("bold");
        padding-right: units(0.5);
        text-align: left;
        text-indent: 0;
        width: units(3);
      }
    }
  }
}

.site-accordion-code {
  .usa-accordion__button {
    @include add-background-svg("minus-thin-white");
    background-color: color("primary-vivid");
    color: color("white");

    &:hover {
      background-color: color("primary-darker");
    }

    &[aria-expanded="false"] {
      @include add-background-svg("plus-thin-white");
    }
  }
}

.site-accordion-docs,
.site-accordion {
  .usa-accordion__button {
    @include add-background-svg("minus-thin-ink");

    &[aria-expanded="false"] {
      @include add-background-svg("plus-thin-ink");
    }
  }
}

.site-component-section {
  @include typeset("lang");
  @include u-measure(5);
  margin-top: units(5);

  > h2 {
    @extend %header-margin;
    @include typeset("lang", "2xl", 2);
    color: color("ink");
    margin: 0;
  }

  .site-text-intro {
    @extend .site-text-intro;
  }
}

.site-component-usage {
  p + * {
    margin-top: 1em;
  }

  h2,
  h3,
  h4,
  h5,
  h5 {
    @extend %header-margin;
    color: color("ink");
    margin: 0;
  }

  h3 {
    @include typeset("lang", "lg", 2);
  }

  h4 {
    @include typeset("lang", "lg", 2);
    color: color("base-dark");
    font-weight: fw("thin");
  }
}

%header-margin {
  * + & {
    margin-top: 2em;
  }
  & + * {
    margin-top: 1em;
  }
}

.site-button {
  @include u-radius('pill');
  @include u-text('normal');
  &[class*=--big] {
    @include u-font('sans', 'md');
  }
}
.usa-section--dark, .usa-dark-background {
  .usa-button:not(.usa-button--accent-cool):not(.usa-button--accent-warm):not(.usa-button--outline) {
    @include u-color('white');
  }
}

.site-button-list {
  .usa-button {
    @include typeset("lang", null, 1);
  }
}

.site-nav {
  @include typeset("lang");

  .usa-accordion {
    @include typeset("lang");
  }

  .usa-nav__close {
    @include add-background-svg("close-blue-60v-alt");
    @include u-square(6);
    background-color: color("base-lightest");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: units(2);
  }

  .usa-nav__link {
    color: color("white");

    .usa-nav__primary-item {
      a {
        color: color("white");
        line-height: lh("lang", 2);
      }
    }
    &[aria-expanded="true"] {
      @include add-background-svg("minus-thin-ink");
    }
    &[aria-expanded="false"] {
      @include add-background-svg("plus-thin-ink");
    }
  }

  .usa-nav__primary button {
    color: color("white");
    line-height: lh("lang", 2);
  }

  .usa-nav__submenu-item {
    color: color("white");
    @include typeset("lang", "2xs", 2);
  }

  .usa-nav__inner {
    .usa-nav__primary button {
      color: color($theme-color-base-dark);
    }
    .usa-nav__primary-item :hover{
      color: color($theme-color-primary-dark);
    }
  }
}

.usa-nav__primary-item {
  .nav-svg {
    // #5c5c5c
    filter: invert(32%) sepia(0%) saturate(4070%) hue-rotate(139deg) brightness(105%) contrast(88%);

  }
  &:hover {
    .nav-svg {
    // #1a4480
      filter: invert(22%) sepia(54%) saturate(1282%) hue-rotate(185deg) brightness(93%) contrast(96%);
    }
  }
}

.site-banner {
  @include typeset("lang");
  background-color: color("primary-lightest");
  color: color("primary-dark");

  [href^="http"]:not([href*=".gov"])::after {
    display: none;
  }

  .usa-accordion {
    @include typeset("lang");
  }

  .usa-banner__header-text,
  .usa-banner__header-action,
  .usa-banner__button {
    @include typeset("lang", 1, 2);
  }

  .usa-banner__content {
    @include typeset("lang", "xs", 5);

    p {
      line-height: lh("lang", 5);
    }
  }

  .usa-banner__header-action {
    @include add-icon(
      "angle-arrow-down-primary",
      "after",
      1,
      1,
      0.5,
      "no-hover"
    );
  }

  .usa-banner__button {
    @include at-media("tablet") {
      @include add-icon(
        "angle-arrow-down-primary",
        "after",
        1,
        1,
        2px,
        "no-hover"
      );
    }

    &[aria-expanded="true"] {
      @include at-media("tablet") {
        @include add-icon(
          "angle-arrow-up-primary",
          "after",
          1,
          1,
          2px,
          "no-hover"
        );
      }
    }
  }
}

.usa-content-list {
  max-width: measure($site-measure);
}

.site-table,
.usa-prose > .site-table {
  @include typeset("lang");
}

.usa-accordion__button:hover {
  text-decoration: none;
}

.usa-hero {
  background-image: none;
  background-color: color("base-darkest");
  @include u-padding-top(15);
  @include u-padding-bottom(15);

}

.usa-hero__title {
    font-size: 48px;
    font-weight: 600;
    line-height: 1.35;
    @include u-padding-top(7);

}

.usa-hero__subtitle {
    @include u-padding-bottom(7);
    a {
      color: color($theme-color-base-lighter);
      &:visited {
        color: color($theme-color-base-light);
      }
    }
}

figure.highlight {
  margin: 0;
}

pre.lineno {
  color: color("base");
  font-weight: fw("light");
  margin-right: 2ch;
  text-align: right;
  width: 3ch;
}

[class*="language-"] {
  color: color("base");

  .nt {
    color: color("primary-dark");
  }

  .s {
    color: color("primary-vivid");
  }
}

#utility-mixins {
  p,
  ul {
    @include u-measure(5);
  }
}

body {
  text-rendering: optimizeLegibility;
}

.site-accordion {
  .usa-accordion__content {
    padding-left: 0;
    padding-right: 0;
  }
}

.site-note {
  @include u-bg("warning");
  @include u-measure(5);
  @include u-padding(1);
  @include u-radius("sm");

  a {
    color: color("ink");
  }
}

.site-hero {
  @include u-bg("base-lightest");
  @include u-padding-bottom(4);
  @include u-padding-x(0);
  background-image: url("../img/introducing-uswds-2-0/feature-image.png");
  background-repeat: no-repeat;
  background-position: 0 units(2);
  background-size: 100% auto;
  padding-top: 68vw;

  @include at-media("tablet") {
    @include u-padding-bottom(8);
    @include u-padding-top(6);
    background-image: url("../img/introducing-uswds-2-0/feature-image-homepage.svg");
    background-repeat: no-repeat;
    background-position: left calc(#{units("mobile-lg")} + #{units(2)}) top
      units("105");
    background-size: auto 400px;
  }

  @include at-media("tablet-lg") {
    background-position: left calc(50% + #{units("card-lg")}) top units("105");
  }
}

.post-content h2 {
  line-height: lh("lang", 2);
}

.page-website-standards {
  .site-subheading {
    display: none;
  }
}

.site-nav {
  // Search input gets cutoff on mobile.
  .usagov-search-autocomplete {
    min-width: 0;
  }
  .usa-button {
    display: block;
  }
}

.site-button-github {
  .logo {
    bottom: units(2px);
    display: inline-block;
    fill: white;
    margin-right: units(0.5);
    vertical-align: middle;
    width: units(3);

    path {
      fill: white;
    }
  }

  .text {
    bottom: units(-2px);
    position: relative;
    @include at-media("desktop") {
      @include add-sr-only;
    }
  }

  @include at-media("desktop") {
    &.usa-button {
      @include u-padding-x(0);
      background-color: color("transparent");
      display: inline-block;

      .logo {
        @include u-padding-x(0);
        bottom: units(1px);
        margin-left: units(0.5);
        position: relative;
        width: units(4);

        path {
          fill: color("white-transparent-70");
        }
      }

      &:hover,
      &:active {
        background-color: color("transparent");

        .logo {
          path {
            fill: color("white");
          }
        }
      }
    }

    .text {
      @include add-sr-only;
    }
  }
}

.embed-youtube {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.site-roadmap-list,
.site-roadmap-list__sublist {
  @include add-list-reset;
}

.site-roadmap-list {
  @include u-margin-top(2);
}

.site-roadmap-list__item,
.site-roadmap-list__subitem {
  @include u-border-top(1px);
  @include u-line-height("lang", 3);
  @include u-padding-top(0.5);
  @include u-margin-top(0.5);
  @include u-display("flex");
  @include u-flex("wrap", "align-start");
}

.site-roadmap-list__item {
  @include u-border-top("ink");
  @include u-text("bold");

  .usa-tag {
    @include u-flex("auto");
  }
}

.site-roadmap-list__sublist {
  @include u-width("full");

  .site-roadmap-list__item {
    @include u-border-top("base-lighter");
    @include u-text("normal");
  }
}

/**
 * callout expander classes
 */
 .callout-button {
  text-transform: uppercase;
  font-size: 0.85em;
  letter-spacing: 1px;
  cursor: pointer;

  @include add-background-svg("angle-arrow-down-white");
  padding: 0 2em 0 0;
  border: none;
  background-repeat: no-repeat;
  background-size: 1.2rem;
  background-position: right 0 top 53%;
  background-color: transparent;

  &.show-less {
    @include add-background-svg("angle-arrow-up-white");
  }
}

.secondary-callout {
  max-width: calc(#{units("tablet-lg")} - #{units(7)});
  @include u-bg('primary-lightest');
  @include u-margin-x('auto');
}

.grid-section {
  // @include u-bg('white');
  @include grid-container;
  @include u-maxw('desktop');
  @include u-padding-x(2);
}

.write-a-story {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2.5rem 0;
}

.flex-space-between {
  justify-content: space-between;
}

.card-header,
.news-blurb,
.usa-media-block__background-image-landscape {
  h3 a {
    position: relative;
    &::after {
      position: absolute;
      content: "→";
      bottom: 0;
      left: calc(100% + .3rem);
    }
  }
}

.card-container {
  @include u-height('mobile');
  @include u-padding-top(3);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
}

.card-header {
  bottom: units(1);
  background-color: color("base-darkest");
  color: color("white");
  line-height: 1.3;
  padding-left: units(3);
  padding-right: units(3);
  padding-top: units(3);
  padding-bottom: units(3);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  h3 {
    font-size: 2em;
    margin: 0;
  }

  .card-date {
    order: -1;
  }
  /*
   cards go from col-12 to col-4 at tablet.
   b/w tablet and tablet-lg, there's too much text on them.
   hide the details text until we get to tablet-lg
  */
  .card-details {
    @include at-media('tablet') {
      @include at-media-max('tablet-lg') {
        display: none;
      }
    }
  }

  a {
    text-decoration: none;
    color: color("white");

    &:hover {
      color: color("base-light");
      text-decoration: underline;
    }
  }

  @include at-media("tablet") {
    @include u-margin-right(4);
    @include u-padding-right(5);
  }
  @include at-media("desktop") {
    @include u-margin-bottom(3);
  }
}
.usa-card__container {
  a {
    height: 100%;
    // text-decoration: none;
  }
  .card-header {
    @include u-radius($theme-card-border-radius);
  }
}

.usa-card__container > .card-header {
  @include u-radius($theme-card-border-radius);
}

.short-card {
  @include u-maxh('card-lg');
  @include u-margin-bottom(2);

  &:last-child{
    @include u-margin-bottom(0);
  }

  .card-header{
    @include u-bg('transparent');
    @include u-margin-bottom(0);
  }
}

.small-header-card .card-header h3 {
  @include u-font('body', 'lg');
}

h2.section-header, h3.section-header {
  @include u-color('base-darkest');
  @include u-margin-bottom(1);
  @include u-padding-bottom(1);
  @include u-line-height('sans', 3);
  @include u-border-bottom(1px);
  @include u-border-bottom('base-light');
  font-size: $site-h2-size;
}

.usa-section--dark, .usa-dark-background {
  h2.section-header, h3.section-header {
    @include u-color('white')
  }
}
.usa-dark-background {
  background-color: initial;
}

.secondary-header {
  color: color("secondary");
  text-transform: uppercase;
  font-weight: 300;
  font-size: font-size('sans', '3xs');
  margin: 1.5rem 0 .5rem 0;
  &:first-child {
    margin-top: 0;
  }
}

.problem-statement-subheader {
  @include u-font-size('sans', 3);
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;
}

.news-blurb-grid-item {
  @include grid-col(12);
  @include u-padding-top(2);

  @include at-media('tablet') {
    @include grid-col(6);
  }
  @include at-media('tablet-lg'){
    @include grid-col(4);
  }
  @include at-media('desktop'){
    padding-left: units($theme-column-gap-mobile / 2) !important;
    padding-right: units($theme-column-gap-mobile / 2) !important;
  }

}

.news-blurb {
  @include u-outline('1px');
  @include u-outline-color('base-lighter');
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  @include u-padding-y(2);
}
.news-blurb.large-blurb {
  flex-direction: row;
  padding: 0;
}

.dark-link {
  @include dark-link;
}

.prize-winner-product {
  @include u-margin-y(3);
  @include u-padding-x(0);

  .grid-row {
    @include u-padding-y(3);
    @include u-border-bottom('1px');
    @include u-border-color('base-light');
    > * {
      @include u-padding-left(2);
    }

  }
  &:last-child .grid-row {
    @include u-border-bottom(0);
    @include u-padding-bottom(0);
  }
}

.markdownify-column > p {
  @include u-margin-top(0);
}

.four-oh-four {
  height: 50vh;
  @include u-padding-x(4);
  @include at-media('tablet'){
    height: 80vh;
  }
}

.transparent-img__container {
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

@import 'problem-statement.scss';
@import 'animated-graphs.scss';
@import 'coil-site.scss';
@import 'event-announcement.scss';
@import 'pagination.scss';
@import 'process-list.scss';
@import 'vision-animation.scss';
@import 'homepage-dots.scss';
