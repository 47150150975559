@mixin dark-link {
  text-decoration: none;
  color: color("black");
  &:hover, &:focus {
    text-decoration: underline;
  }
  &:visited, &:hover, &:focus {
    color: color("black");
  }
}

@mixin dark-links {
  a {
    @include dark-link;
  }
}

@mixin white-link {
  color: color("white");
  &:visited {
    color: color("white");
  }
  &:hover, &:focus {
    color: color("primary-lighter")
  }
  &:hover, &:focus {
    text-decoration: underline;
  }
}
.white-link {
  color: color("white");
  &:visited {
    color: color("white");
  }
  &:hover, &:focus {
    color: color("primary-lighter")
  }
  &:hover, &:focus {
    text-decoration: underline;
  }
}
@mixin white-links{
  a {
    @include white-link;
  }
}